/* Container with some breathing room */


/* Grid Layout */
.learn-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    justify-items:center;
}

/* Responsive grid for larger screens */
@media (min-width: 768px) {
    .learn-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Grid Items Styling */
.grid-item {
    width: 100%;
    max-width: 900px;
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
}

/* Maintain 16:9 Aspect Ratio */
.aspect-ratio {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.aspect-ratio iframe,
.aspect-ratio img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}
