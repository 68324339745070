/* Dark background overlay */
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;
}

/* Tutorial popup */
.tutorial-container {
  font-family: "Poppins", sans-serif;
  background: #191919;
  padding: 30px;
  border-radius: 8px;
  max-width: 750px;
  text-align: center;
  z-index: 100000000000000000000000000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.2s ease-in-out;
  margin: auto 20px;
}

/* Fade-in and Fade-out effect */
.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.fade-out {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

/* Title */
.tutorial-content h2 {
  margin-bottom: 20px;
  color: white;
}

.tutorial-content p {
  margin: 10px 0px;
  margin-bottom: 25px;
}
/* Tutorial image */
.tutorial-image {
  aspect-ratio: 16 / 9; /* Ensures it always maintains a 16:9 ratio */
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 6px;
  border: solid 1px #0252ff;
}

/* Buttons container */
.tutorial-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 15px;
}

/* Button styles */
.tutorial-content button {
  background-color: #0252ff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}

.tutorial-content-container{
  background-color: #121212;
  padding: 20px;
  border-radius: 10px;
}

.tutorial-content button:hover {
  background-color: #0026ff;
}

/* Disabled button styling */
.tutorial-content button:disabled,
.tutorial-content .disabled {
  display: none;
}



/* Slide counter (e.g., 1/5) */
.tutorial-counter {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.117);
  padding: 5px 10px;
  border-radius: 5px;
}




.tutorial-affiliate-link-container {
  align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin: 20px 0px;
}

.tutorial-affiliate-link-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #424242;
  background-color: #1e1e1e;
  color: #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
}

.tutorial-affiliate-copy-button {
  background-color: #004eff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    font-weight: 400;
    padding: .5rem 1rem;
    width: 150px !important;
}

.tutorial-affiliate-copy-button:hover {
  background-color: #3b76ff;
}



/* Ensure tutorial container doesn't exceed viewport height */
.tutorial-container {
  max-height: 90vh; /* Prevents it from being too tall */
  overflow-y: auto; /* Adds scrolling if content overflows */
}

/* Constrain image size */
.tutorial-image {
  max-height: 40vh; /* Prevents it from pushing content out of the viewport */
  object-fit: contain; /* Ensures it scales properly without cropping */
  width: 100%;
}






@media (max-width: 768px) {
.tutorial-affiliate-link-container {
  align-items: center;
  display: flex
;
  flex-direction: column;
  gap: .5rem;
  margin-bottom: 0rem;
}
}