.storefront-polls-container{
  padding: 20px;
}


.storefront-share-button {
  color: grey;
  margin-right: 12px;
  position: absolute;
 right: 20px;
}


.ad-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
