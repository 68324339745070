.create-poll-page {
      /* background: #171717; */
      border-radius: 15px;
      color: #fff;
      font-family: Poppins;
      /* max-width: 800px; */
      /* min-width: 700px; */
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
}





.custom-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;
}

.custom-switch-2 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-switch input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.custom-switch .switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  background-color: #535353;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-switch .switch-slider {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-switch input[type="checkbox"]:checked + .switch-label {
  background-color: #20f827; /* Change color when checked */
}

.custom-switch input[type="checkbox"]:checked + .switch-label .switch-slider {
  transform: translateX(24px); /* Move slider to the right when checked */
}

.custom-switch .switch-text {
  color: #ffffff;
  font-size: 14px;
}

/* Optional: Add subtle glow animation when toggled */
.custom-switch input[type="checkbox"]:checked + .switch-label {
  box-shadow: 0 0 8px rgba(146, 255, 149, 0.6);
}





.custom-switch-2 input[type="checkbox"] {
  display: none; /* Hide the default checkbox */
}

.custom-switch-2 .switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  background-color: #004cff;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-switch-2 .switch-slider {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-switch-2 input[type="checkbox"]:checked + .switch-label {
  background-color: #20f827; /* Change color when checked */
}

.custom-switch-2 input[type="checkbox"]:checked + .switch-label .switch-slider {
  transform: translateX(24px); /* Move slider to the right when checked */
}

.custom-switch-2 .switch-text {
  color: #ffffff;
  font-size: 14px;
}

/* Optional: Add subtle glow animation when toggled */
.custom-switch-2 input[type="checkbox"]:checked + .switch-label {
  box-shadow: 0 0 8px rgba(146, 255, 149, 0.6);
}





.preview-container{
  max-width: 400px;
  min-width: 400px;
}

.preview-title{
  font-size: 24px;
  color: #767676;
  margin-top: 10px;
}


.promotions-headline{
  font-size: 20px;
}

.promotion-mode{
  appearance: none;
    background-color: #212121;
    border: 2px solid #555;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    max-width: 400px;
    outline: none;
    padding: 10px 15px;
    transition: all .3s ease;
    width: 100%;
}

.promotion-mode option{
  background-color: #272727;
    color: #fff;
}


.create-poll-content{
  background-color: #191919;
  padding: 20px;
  min-width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}


.create-poll-content {
  border-radius: 10px;
  text-align: left;
}

.create-poll-content-ads{
  width: 400px;
  background-color: #191919;
  padding: 20px;
}


.create-poll-content-ads {
  border-radius: 10px;
  text-align: left;
  margin-right: 20px;
  margin-bottom: 20px;
}


.page-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
  color: #fff;
  text-align: center;
}


.redirect-input,
.redirect-message {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-family: 'Poppins';
  font-size: 16px;
  resize: none;
  height: 50px;
  width: 96%;

}

.poll-question-input{
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-family: 'Poppins';
  font-size: 16px;
  resize: none;
  height: 50px;
  width: 96%;
}

.poll-question-input {
  background: #212121;
  border: 1px solid #555;
  border-radius: 5px;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  padding: 10px;
  padding-right: 40px; /* Space for button */
  resize: none;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Hides scrollbar initially */
  min-height: 70px; /* Adjust this to set initial size for about two lines */
}



.loading-icon {
  width: 100%;
  height: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textarea-container {
  position: relative;
  width: 100%;
}

.ai-generate-button {
  align-items: center;
    background-color: #292929;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: 8px 10px;
}

.ai-generate-button img {
  width: 20px;
  height: 20px;
}

.ai-generate-button span {
  font-size: 16px; /* Adjust the text size */
  font-weight: 400;
}




.page-buttons {
  justify-content: center;
  gap: 10px;
}

.create-button {
  padding: 15px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    background: #00d131;
    color: #fff;
    width: 100%;
    margin-top: 10px;
}

.image-options-small-text{
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
  color: gray;
  margin-bottom: -10px;
}

.preview-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background: #0066ff;
  color: #fff;
  width: 100%;
  margin-top: 10px;
}

.preview-description{

  font-size: 14px;
  color: grey;
}



.edit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background: #535353;
  color: #fff;
  width: 100%;
  margin-top: 20px;
}

.upload-logo-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #292929;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  height: 35px;
  margin-top: 10px;
  margin-left: 0px;
  font-family: 'Poppins';
  width: 50%;
}

.image-upload-container {
  margin-bottom: 10px;
  margin-top: 15px;
}

.error-message {
  color: #dc3545;
  margin-top: 5px;
}

.extra-options {
  margin-top: 20px;
}



.poll-category {
  margin-top: 20px;
}

.category-select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}

.poll-auth-method {
  margin-top: 10px;
}

.auth-method-select  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-size: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23fff" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  padding-right: 30px;
  margin-bottom: 10px;
}

.visibilty-select  {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-size: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23fff" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  padding-right: 30px;
  margin-bottom: 5px;
}

.poll-visibility {
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio-label {
  font-family: 'Poppins';
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.poll-logo {
  max-width: 130px;
  max-height: 80px;
  cursor: pointer;
}

.logo-background {
  display: flex;
  padding: 0px;
  border-radius: 8px;
  background-color: transparent;
  width: fit-content;
  padding-bottom: 0px;
}

.logo-background.filled {
  background-color: white;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: 16px;
  margin-bottom: 20px;
}

.loading-spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.image-options-title {
  color: white;
  font-weight: 400;
  font-size: 16px;
  margin-left: 3px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.image-action-title {
  font-size: 16px;
  font-weight: 400;
  margin-left: 3px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.custom-checkbox {
  display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 1px;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: #2c2c2c;
  border: 2px solid #555;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #326ef1;
  border-color: #326ef1;
}

.custom-checkbox input[type="checkbox"]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 2px;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.1s ease-in-out;
}

.custom-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1) translate(-50%, -50%);
}

.custom-checkbox label {
  font-family: 'Poppins';
  color: white;
  font-size: 16px;
}


.custom-date-input {
  background-color: #2c2c2c; /* Dark background */
  color: #ffffff; /* Light text color */
  padding: 5px 15px; /* Padding for better click area */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #555; /* Slight border to differentiate from background */
  cursor: pointer; /* Indicate it's clickable */
  text-align: center; /* Center the text */
  font-size: 16px; /* Make the text readable */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
  margin-bottom: 10px;
  margin-top: 10px;
}

.custom-date-input:hover {
  background-color: #3d3d3d; /* Slightly lighter on hover */
}

.custom-date-input:active {
  background-color: #4a4a4a; /* Even lighter when active */
}

/* Confidence levels container */
.confidence-levels-inputs {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.main-option-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}


.add-confidence-level-button {
  background: #292929;
  border-radius: 4px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

/* Confidence levels container */
.confidence-levels-inputs {
  align-items: baseline;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; /* Make the container full width */
}

/* Individual confidence level input styling */
.confidence-input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  flex: 1; /* Allows flex-grow for equal spacing */
  min-width: 120px; /* Minimum width for smaller screens */
  max-width: calc(33% - 10px); /* For larger screens */
  box-sizing: border-box; /* Include padding in width */
}


.custom-labels-inputs {
  padding: 0px; /* Increase padding for larger input fields */
  border-radius: 3px;
  color: #fff; /* Light text */
  margin-bottom: 10px; /* Add margin between the input fields */
  font-size: 16px;
  padding-top: 10px;
}
.confidence-input::placeholder {
  color: white; /* Set the placeholder text color to white */
  opacity: 1;   /* Ensure the opacity is set to 1 to make it fully visible */
}



.confidence-input-container {
  align-items: baseline;
  display: flex;
  position: relative;
  flex: 1;
  max-width: 150px;
}

.confidence-input {
  flex: 1; /* Allows flex-grow for equal spacing */
  padding-right: 25px; /* Add padding to the right to make space for the delete button */
  min-width: 120px; /* Minimum width for smaller screens */
  max-width: calc(33% - 10px); /* For larger screens */
}

.delete-confidence-button {
  margin-left: -25px; /* Align to the right edge of the input field */
  background: none;
  border: none;
  color: #ff4d4f; /* Red color for the delete button */
  font-size: 14px;
  cursor: pointer;
  padding: 0; /* Remove any padding to prevent space issues */
  width: 20px; /* Small width for the button */
  height: 20px; /* Small height for the button */
  line-height: 0; /* Adjust line-height to prevent extra space */
}





/* Define the keyframes for the pop-out animation */
@keyframes popOut {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  70% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

/* Add the animation class for the main option fields */
.main-option-input.animated,
.confidence-input.animated {
  animation: popOut 0.2s ease-out; /* Use the pop-out animation */
}


@media (max-width: 768px) {

  


  .custom-labels-inputs {
    border-radius: 3px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 0px;
    padding-top: 10px;
}

  .create-poll-page {
    background: #0000;
        border-radius: 0;
        color: #fff;
        font-family: Poppins;
  }

  .create-poll-content {
    background-color: #191919;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 0px;
    min-width: 0px;
    padding: 20px;
    text-align: left;
}

.preview-container{
  min-width: 0px;
  width: 100%;
}

.upload-logo-button {
  background-color: #292929;
  font-family: Poppins;
  height: 35px;
  margin-left: 0;
  margin-top: 10px;
  padding: 5px 10px;
  width: 80%;
}


.create-button {
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  background: #00d131;
  color: #fff;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 0px;
}

  .confidence-levels-inputs {
    align-items: baseline;
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
}

  .confidence-input {
    max-width: 100%; /* Full width on small screens */
    flex: initial; /* Remove flex-grow on smaller screens */
  }



}


.link-confidence-button-container {
  margin-bottom: 10px;
}

.link-confidence-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  font-size: 12px;
  color: #fff; /* Adjust the color as needed */
  margin-bottom: 10px;
  border-radius: 5px;
}

.link-confidence-button.linked {
  background-color: #007eff; /* Linked state color */
}

.link-confidence-button.unlinked {
  background-color: #292929; /* Unlinked state color */
}


/* Add this to your CSS to ensure consistent input sizes */
.main-option-input {
  width: 100%;
  padding: 10px; /* Increase padding for larger input fields */
  border: 2px solid #555; /* Match dark theme */
  border-radius: 5px;
  background-color: #212121; /* Dark background */
  color: #fff; /* Light text */
  font-size: 20px;
}

.main-option-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Ensure spacing between buttons */
}

/* Confidence levels for Option 1 (Green border with varying opacity) */




/* Common styles for all confidence inputs */
.confidence-input {
  padding: 8px;
  margin-bottom: 10px;
  background: #212121;
  color: #fff;
  border-radius: 5px;
  flex: 1;
  min-width: 120px;
  box-sizing: border-box;
}











/* Container for each input field and its delete button */
.confidence-input-container {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
  
}

/* Individual confidence level input styling */
.confidence-input {
  flex: 1; /* Allows flex-grow to take full available width */
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-family: 'Poppins';
  font-size: 14px;
  margin: 0; /* Remove margin to reduce spacing */
  padding-right: 25px; /* Space for the delete button */
  box-sizing: border-box;
  width: 100%; /* Full width within the container */
  min-width: 80px; /* Minimum width to prevent collapsing too much */
  min-width: 100%;
}

/* Delete button styling */
.delete-confidence-button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin-left: -25px; /* Negative margin to overlap with the input */
  width: 20px;
  height: 20px;
  line-height: 0;
  position: relative; /* Positioned relative to allow alignment */
  right: 0;
}

.add-main-opinion-button{
  font-size: 16px;
  background: #292929;
  width: 150px;
  padding: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}



.delete-main-opinion-button{
  background: red;
  padding: 5px;
  width: 30px;
  border-radius: 4px;
}















.keyword-section {
  margin-top: 12px;
}

.keywords-container {
  display: flex;
  flex-wrap: wrap;
}

.keyword-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  background-color: #0066ff;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.keyword-item button {
  margin-left: 8px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
}

.keyword-input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #212121;
  color: #fff;
  font-family: 'Poppins';
  font-size: 16px;
  resize: none;
  height: 20px;
  margin-top: 5px;
  width: 96%;
}



