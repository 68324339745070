.home-share-container {
  margin-left: 40px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.home-share-container.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Arrow icon position */
.home-share-arrow {
  position: absolute;
  left: -40px; /* Push it outside */
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

/* Main share box */
.home-share-box {
  align-items: center;
  background: #1a1a1a;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  padding: 10px 15px;
}

/* Link text */
.home-share-link {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  text-decoration: none; /* Remove default underline */
  cursor: pointer; /* Show pointer on hover */

}


.view-demo-button {
  display: flex;
  flex-direction: row-reverse;
  text-decoration: none;
  font-size: 14px;
  text-decoration: none;
}

.view-demo-button:hover {
  background-color: #0056b3;
}

.home-share-icon {
  height: 20px;
  margin-right: 5px;
  width: 20px;
  margin-bottom: 2px;
}

.home-share-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Buttons */
.home-share-button {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.2s ease-in-out, transform 0.1s ease-in-out;
}

/* Hover effect - Background turns blue */
.home-share-button:hover {
  background: #0252ff;
}

/* Click effect - Slight scale */
.home-share-button:active {
  transform: scale(0.95);
}









@media (max-width: 768px) {

.home-share-container {
  margin-left: 20px;
  font-size: 12px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}
}

