.info-container {
  position: relative; /* Allows the popup to position relative to this container */
  display: inline-block; /* Allows multiple info icons to be placed inline */
  margin-left: 5px;
}

.info-icon {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-icon.active {
  background-color: transparent; /* No background color change */
}

.info-popup {
  position: absolute; /* Position relative to the info icon */
  top: 30px; /* Adjust to position the popup below the icon */
  left: 50%; /* Center horizontally relative to the icon */
  transform: translateX(-50%); /* Adjust to center */
  background-color: #333;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  z-index: 1000000; /* Ensure the popup appears above other elements */
  width: 200px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
}

.loading-products .info-popup{
  position: absolute; /* Position relative to the info icon */
  top: 30px; /* Adjust to position the popup below the icon */
  left: -150%; /* Center horizontally relative to the icon */
  transform: translateX(-50%); /* Adjust to center */
  background-color: #333;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  z-index: 1000000; /* Ensure the popup appears above other elements */
  width: 200px;
  border-radius: 5px;
  font-size: 14px;
  color: white;

}
