.poll-success-modal-overlay {
  align-items: center;
  background: #000000cf;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999999;
  }
  
  .poll-success-modal-content {
    background: #121212;
    border-radius: 8px;
    max-width: 500px;
    padding: 20px;
    text-align: left;
    width: 400px;
    border: solid 1px grey;
  }


  .poll-qr-modal-content{
    display: flex;
    flex-wrap: wrap;
    background: #121212;
    border: 1px solid grey;
    border-radius: 8px;
    max-width: 500px;
    padding: 20px;
    text-align: left;
    width: 400px;
    flex-direction: column;
    align-items: center;
  }
  
  .poll-success-modal-title {
    font-size: 22px;
  }
  
  .poll-success-link-container {
    align-items: center;
    display: flex;
    /* justify-content: center; */
    margin-bottom: 20px;
  }
  
  .poll-success-link-input {
    width: 80%;
    padding: 10px;
    border: 2px solid #858585;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
    color: white;
  }
  
  .poll-success-copy-button {
    background: #333;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 12px 15px;
    transition: background-color .3s ease;
  }
  
  .poll-success-copy-button:hover {
    background-color: #0075ff; /* Blue color on hover */
    color: #fff; /* Keep the text/icon color white */
  }
  
  .poll-success-copy-button.copied {
    background-color: #0075ff; /* Keep blue color when copied */
    color: #fff; /* Keep the text/icon color white */
  }
  
  .poll-success-modal-buttons {
    display: flex;
    justify-content: left;
    gap: 10px;
  }
  
  .poll-success-close-button {
    background: #333;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 15px;
    transition: background-color .3s ease;

  }

  .poll-success-close-button:hover {
    background-color: #ffffff; /* Blue color on hover */
    color: #111111; /* Keep the text/icon color white */
  }
  
  

  .poll-success-share-button {
    background: #555555;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  .poll-success-animation-container {
    position: absolute;
    top: -50px; /* Adjust this value to position the animation correctly */
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  