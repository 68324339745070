.youtube-comments-container {

  align-items: start;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
 
    overflow-y: hidden; /* Prevent browser from auto-scrolling */
  
  
  }
  
  .youtube-comment {
    background: #1a1a1a;
    padding: 8px 12px;
    margin: 5px;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0.6;
    width: 700px;  
    text-align: left;
    height: 80px;
  
  }

  .youtube-comment p {
   font-size: 14px;
   text-align: left;
  }


  @media (max-width: 768px) {


    .youtube-comment {
      background: #1a1a1a;
      padding: 8px 12px;
      margin: 0px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 14px;
      opacity: 0.6;
      width: auto;  
      text-align: left;
      height: 80px;
    
    }


    .youtube-comments-container {

      align-items: start;
      border-radius: 10px;
      color: #fff;
      display: flex;
      flex-direction: column;
      font-family: Arial, sans-serif;
      overflow: hidden;
      position: relative;
      width: auto;  
      margin-left: 10px;
      margin-right: 10px;
     
        overflow-y: hidden; /* Prevent browser from auto-scrolling */
      
      
      }


    .comments-container {
      display: flex
  ;
      flex-direction: column;
      align-items: center;
  }
  

  }
  
  /* @keyframes popIn {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  } */
  