/* Container for Amazon Clicks */
.amazon-clicks-container {
  color: #fff;
  font-family: 'Poppins';
}

/* Header section */
.clicks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.clicks-header h2 {
  font-size: 1.5rem;
}

.last-updated {
  font-size: 0.9rem;
  color: #a5a5a5;
}

/* Two-column layout */
.clicks-grid {
  display: flex;
  gap: 20px;
}

/* Clicks and Purchases list containers */
.clicks-list,
.purchases-list {
  flex: 1;
  background: #191919;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.clicks-list h3,
.purchases-list h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: left;
}

/* Unordered lists */
.clicks-list ul,
.purchases-list ul {
  list-style: none;
  padding: 0;
}

/* Click item styling */
.click-item {
  background: #0f0f0f;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 15px;
  align-items: flex-start;
  gap: 5px;
}

.click-product {
  font-weight: bold;
}

.click-price {
  font-size: 0.9rem;
  color: #64d169;
}

.click-time {
  font-size: 0.8rem;
  color: #a5a5a5;
}

/* Purchase item styling */
.purchase-item {
  background: #0f0f0f;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  padding: 15px;
  align-items: flex-start;
  gap: 5px;
}

.purchase-product {
  font-weight: bold;
}

.purchase-price,
.purchase-adfee,
.purchase-items,
.purchase-date {
  font-size: 0.9rem;
  color: #64d169;
}

/* "No purchases" message */
.purchases-list p {
  font-size: 1rem;
  color: #888;
  text-align: left;
}


/* Purchases Chart Container - Styled as a Card */
.purchases-chart-container {
  margin-top: 20px;
  background: #191919;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .clicks-grid {
    flex-direction: column-reverse;
    gap: 15px;
  }
  .clicks-list,
  .purchases-list {
    padding: 15px;
  }
  .clicks-header h2 {
    font-size: 1.2rem;
  }
  .clicks-list h3,
  .purchases-list h3 {
    font-size: 1.1rem;
  }
  .click-item,
  .purchase-item {
    padding: 10px;
  }

  .purchases-chart-container {
    background: #191919;
    border-radius: 10px;
    box-shadow: 0 4px 8px #0003;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 5px;
    padding-left: 0px;
}
}




/* Dropdown Container Styling */
.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Base Dropdown Styling */
.filters select {
  background-color: #191919; /* Match card background */
  color: #fff; /* White text */
  border: 1px solid #444; /* Border for dropdown */
  border-radius: 5px; /* Rounded corners */
  padding: 8px 12px; /* Padding inside the dropdown */
  font-size: 14px; /* Font size */
  font-family: 'Poppins', sans-serif; /* Match the font */
  appearance: none; /* Remove default dropdown arrow styling */
  cursor: pointer; /* Pointer cursor for interaction */
  transition: all 0.2s ease-in-out; /* Smooth transition on hover */
}

/* Dropdown Hover and Focus */
.filters select:hover,
.filters select:focus {
  border-color: #5c5c5c; /* Green border on hover/focus */
  outline: none; /* Remove focus outline */
}

/* Dropdown Arrow */
.filters select::after {
  content: '▼'; /* Custom arrow icon */
  position: absolute;
  right: 12px;
  pointer-events: none;
}

/* Adjust Dropdown Width */
.filters select {
  min-width: 150px; /* Ensure enough width for options */
}


/* Mobile Styles */
@media (max-width: 768px) {
  /* Stack columns vertically */
  .clicks-grid {
    flex-direction: column-reverse;
    gap: 15px;
  }


 
  
  /* Adjust card padding for smaller screens */
  .clicks-list,
  .purchases-list {
    padding: 15px;
  }
  
  /* Adjust header sizes for mobile */
  .clicks-header h2 {
    font-size: 1.2rem;
  }
  
  .clicks-list h3,
  .purchases-list h3 {
    font-size: 1.1rem;
  }
  
  /* Adjust click item padding */
  .click-item {
    padding: 10px;
  }
  
  /* Mobile adjustments for filters dropdown */
  .filters {
    flex-direction: column;
    gap: 10px;
  }
  
  .filters select {
    width: 100%;
  }
  
  /* Adjust coming soon text */
  .coming-soon {
    font-size: 0.9rem;
    margin-left: 0;
    text-align: center;
  }
}
