.recharts-tooltip-wrapper {
      /* left: 0 !important; */
    /* pointer-events: none !important; */
    /* position: absolute !important; */
    /* top: -190px !important; */
    /* transition: transform .4s ease 0s !important; */
    top: -100px !important;
    visibility: visible !important;
}

.chart-tooltip {
  background-color: #212121;
  color: #fff;
  border-radius: 4px;
  font-size: 14px !important; /* Force smaller font size */
  z-index: 1000;
  pointer-events: none;
  padding: 8px;
  border: solid 2px white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-tooltip-opinion {
  font-size: 12px !important; /* Smaller font */
  font-family: 'Poppins';
  font-weight: 400;
  margin: 0 5px;
}

.chart-tooltip-timestamp {
  font-size: 12px !important; /* Smaller timestamp */
  font-family: 'Poppins';
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 0px;
}

.chart-tooltip-wrapper {
  top: -200px; /* Adjust this value to move the tooltip upwards */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
}

.chart-tooltip-stats {
  justify-content: center; /* Center the stats horizontally */
  margin-top: 5px;
}
