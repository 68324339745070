.home-container {
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-x: hidden;
}





.hero-container{
  display: flex;
  margin-top: 5rem;
  padding: 0px 20px;
  margin-bottom: 10rem;
  max-width: 1200px; /* optional if you want a maximum limit */
  width: 100%;
    justify-content: space-between;
}

.home-hero-section {
  max-width: 800px;
  text-align: left;
  font-family: 'Poppins';
}

.home-container h1 {
  font-family: 'Poppins';
    font-size: 4rem;
    line-height: 1.25;
    margin-bottom: 30px;
    
}

.home-container p {
  font-size: 1.5rem;
  margin: 10px 0;
}





.home-vote-header{
  display: flex;
  flex-direction: row;
  justify-content: start;}

.home-sign-up-btn {
  font-family: 'Poppins';
  background-color: white;
  color: black;
  padding: 10px 25px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  /* font-weight: bold; */
  margin-top: 280px;
  margin-bottom: 15px;
  
}

.home-poll-cards {
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 20px;
  margin: 0px 30px;
  width: 100%;
  max-width: 1200px;

}

.home-poll-card {
  background-color: #1a1a1a;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 0.9rem;
    height: 500px;
    width: 100%;
    flex-wrap: wrap;
}




.home-poll-cards-container{
  width: 100%;
  display: flex;
justify-content: center;
display: flex;
margin: 5rem 0rem;  
}

.home-poll-card span {
  color: #aaa;
  font-size: 0.8rem;
}

.hero-container h2 {
  color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 5px;
    margin-top: 15px;
    font-size: 1.25rem;
}


.home-vote-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.home-vote-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: magenta;
  border-radius: 50%;
  margin-left: 10px;
}

.home-vote-model {
  font-size: 0.9rem;
  color: #bbb;
  margin: 5px 0;
}

.home-vote-text {
  font-size: 0.9rem;
  color: white;
}

/* Right-side empty container */
.home-poll-container {
  margin-left: 55px;
    width: 350px;
    background-color: #1a1a1a;
    border-radius: 8px;
    height: 600px;
}


.home-poll-container .question-text h3 {
  color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    margin: 0px;
    margin-top: 20px;
}



.home-poll-container .color-picker-container .logo {
  margin-left: auto;
  margin-right: auto;
  max-height: 160px;
  max-width: 110px;
}


.home-poll-container .total-votes{
  font-size: 14px;
}



.home-poll-container .dropdown-toggle{
  font-size: 14px;
  padding: 8px;
}

.home-poll-container .color-picker-container{
  padding: 10px 10px 15px;
}


.custom-shape {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-top: 5rem;
}


/* Right-side empty container */
.second-poll-container {
  border-radius: 8px;
    height: 600px;
    position: relative;
    top: -480px;
    width: 100%;
    max-width: 475px;
}

.second-poll-container .confidence-label{
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 15px;

}


.second-poll-container .question-text h3 {
  color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    margin: 0px;
}


.home-vote-question {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;        /* Prevent wrapping */
  overflow: hidden;           /* Hide overflowing text */
  text-overflow: ellipsis;    /* Show "..." on overflow */
  display: block;             /* Ensures it behaves correctly in a flexbox */
  max-width: 550px;    
  color: rgb(183, 183, 183);  
  margin-bottom: 8px;      /* Ensure it adapts to the container width */
}




.second-poll-container .color-picker-container .logo {
  margin-left: auto;
  margin-right: auto;
  max-height: 160px;
  max-width: 110px;
}


.second-poll-container .total-votes{
  font-size: 14px;
}

.second-poll-container .color-bar-container{
  height: 15px;
}

.second-poll-container .dropdown-toggle{
  font-size: 12px;
}

.second-poll-container .color-picker-container{
  padding: 10px 10px 15px;
}





/* Right-side empty container */
.third-section .second-poll-container {
  border-radius: 8px;
    height: 600px;
    position: relative;
    width: 100%;
    max-width: 375px;
    top: 0px;
}

.third-section .second-poll-container .confidence-label{
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 15px;

}


.third-section .second-poll-container .question-text h3 {
  color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    margin: 0px;
}



.third-section .second-poll-container .color-picker-container .logo {
  margin-left: auto;
  margin-right: auto;
  max-height: 160px;
  max-width: 110px;
}

.third-section .second-poll-container .color-picker-container h2 {
  margin-bottom: 5px;
    margin-top: 15px;
}


.third-section .second-poll-container .total-votes{
  font-size: 14px;
}

.third-section .second-poll-container .color-bar-container{
  height: 15px;
}

.third-section .second-poll-container .dropdown-toggle{
  font-size: 12px;
}

.third-section .second-poll-container .color-picker-container{
  padding: 10px 10px 15px;
  background-color: #121212;
}









.home-live-vote p{
  font-size: 16px;
    margin: 0px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #9b9b9b;
}




.nav-bar-container {
  position: relative;
  background-color: #1a1a1a;
  padding: 5px 20px;
  display: flex;
  justify-content: center;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.nav-navbar {
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-home-logo {
  width: 120px;
  cursor: pointer;
}

.nav-links {
  display: flex
;
  font-family: Poppins;
  gap: 30px;
  list-style: none;
  align-content: center;
  align-items: center;
}



.nav-link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #aaa;
}

.nav-auth {
  display: flex;
  gap: 10px;
  font-family: 'Poppins';

}

.nav-signup,
.nav-login {
  font-family: 'Poppins';
font-size: 14;
  padding: 8px 15px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.nav-dashboard {
  font-family: 'Poppins';
font-size: 14;
  padding: 8px 15px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  background-color: white;
  color: black;
}

.nav-signup {
  background-color: white;
  color: black;
}

.nav-login {
  background-color: #333;
  color: white;
}








.home-vote-location{
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.home-live-votes-container {
  margin-top: 30px;
  width: 100%;
  position: relative;
}

.home-live-votes {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  position: relative;
  width: 80%;
}


.home-live-vote {
  display: flex;
  justify-content: space-between;
  background-color: #1a1a1a;
  padding: 15px;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  transition: 
    top 0.35s ease-out, 
    transform 0.15s ease-out, 
    opacity 0.35s ease-out, 
    background-color 0.35s ease-out, 
    box-shadow 0.35s ease-out;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
  align-items: flex-start;
  cursor: pointer; /* Hand cursor on hover */
}

/* Hover Effect */
.home-live-vote:hover {
  transform: scale(1.05); /* Slightly enlarges the vote */
}

.home-vote-flag{
  width: 30px;
}


.home-live-votes .home-live-vote:nth-child(1) {
  top: 0;
  background-color: #1a1a1a;
}
.home-live-votes .home-live-vote:nth-child(2) {
  top: 40px;
filter: brightness(0.85);
}
.home-live-votes .home-live-vote:nth-child(3) {
  top: 80px;
  filter: brightness(0.75);


}
.home-live-votes .home-live-vote:nth-child(4) {
  top: 120px;
  filter: brightness(0.65);

}

.home-live-votes .home-live-vote:nth-child(5) {
  top: 160px;
  filter: brightness(0.56);


}


/* Animation for new votes */
.new-vote {
  animation: popIn 0.35s ease-out;
}

 @keyframes popIn {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.9);
  }
  60% {
    opacity: 1;
    transform: translateY(2px) scale(1.05);
  }
  100% {
    transform: translateY(0) scale(1);
  }
} 




/* @keyframes popIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  80% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
} */

.home-poll-container {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

/* Add a class that triggers the animation */
.home-poll-pop {
  animation: popIn 0.3s ease-out;
}

.second-poll-container {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}






.second-section {
  padding-top: 2rem;
  background-color: #1a1a1ab8;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 5rem;

}

.second-section h2 {
 font-size: 30px;

}

.second-section-container {
  text-align: left;
  color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}

.third-section-container {
  text-align: left;
  color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
}




.second-section-container p {
  max-width: 800px;
  font-size: 1.25rem;
 
}



.second-section-container .confidence-bar-wrapper {
  margin: 0px auto;
}





.third-section {
  padding-top: 2rem;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0rem;
  padding-bottom: 5rem;

}

.third-section h2 {
 font-size: 30px;
 text-align: right;

}




.third-section-container p {
  text-align: right;
  font-size: 1.25rem;
 
}











.home-card-grid{
  display: inline-flex;
    gap: 5px;
    width: 100%;
    margin-top: 25px;
    background-color: #0c0c0c;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 20px;


}



 

  .card-grid-item-left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    color: #fff; /* White text */
    border-radius: 10px;
    justify-content: flex-start;
    padding-top: 0px;
    padding-bottom: 0px;
  }




 
  


  
 


  .card-grid-item-right {
    display: flex;
    overflow: hidden;
    position: relative;
    /* text-align: center; */
    justify-content: flex-end;
    width: 500px;
  }
  






  .color-picker-container {
    transition: max-height 1s ease-out, opacity 1s ease-out;
    opacity: 1;
    overflow: hidden;
  }
  
  .color-picker-container.open {
    max-height: 2000px; /* Adjust this to fit your content */
    opacity: 1;
  }






.custom-shape {
fill: #1a1a1a;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  margin-top: 3rem;
}




.rotating-text {
  color: #ffffff; /* Highlight color */
  display: inline-block;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.rotating-text-exit {
  opacity: 0;
  transform: translateY(10px);
}

.rotating-text-enter {
  opacity: 1;
  transform: translateY(0);
}



.home-builder-container{
  background-color: #121212;
    border-radius: 10px;
    margin-top: 30px;
    max-width: 1200px;
    width: 100%;
    padding: 20px;
}

.easy-builder{
  display: flex;
    justify-content: space-between;
}

.preview-section{
  width: 100%;
  max-width: 400px;
}

.section-header-text p {
  width: 100%;
    max-width: 1000px;
}




/* When the poll is loading, desaturate and lower brightness/contrast */
.home-poll-container.loading {
  filter: grayscale(70%) brightness(.9) contrast(1);
  position: relative; /* establish positioning context for pseudo-element */
  overflow: hidden;   /* hide overflow from the animation */
}

/* Overlay: A vertical shimmering animated gradient */
.home-poll-container.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Vertical gradient: semi-transparent dark at the edges with a bright band in the middle */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 30%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.2) 100%
  );
  background-size: 100% 200%;
  animation: shimmering 3s infinite linear;
  pointer-events: none; /* allow clicks to pass through */
}

/* Keyframes for the shimmering animation */
@keyframes shimmering {
  0% {
    background-position: 0 -200%;
  }
  100% {
    background-position: 0 200%;
  }
}



































.bento-grid {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Larger on left, smaller on right */
  grid-template-rows: auto auto;
  gap: 16px;
}

.bento-item-2 {
  background: #121212;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    
}

.bento-item:hover {
  transform: translateY(-5px);
}

.bento-item-2.large {
  grid-column: span 1;
  grid-row: span 2;
  height: 400px;
}

.bento-item-2.small {
  grid-column: span 1;
  grid-row: span 1;
}

.bento-item-2.wide {
  grid-column: span 2;
  grid-row: span 1;
}

.bento-item-2.wide img{
  max-width: 1000px;
}

.bento-item-2 h3 {
  font-size: 1.4rem;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 400;
}

.bento-item-2 p {
  font-size: 1rem;
  line-height: 1.5;
}

.bento-item-2 a {
  color: #4fa3ff;
  text-decoration: none;
  font-weight: bold;
}

.bento-item-2 a:hover {
  text-decoration: underline;
}











.shopping-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 40px 0;
  background: #121212;
  white-space: nowrap;
}

/* Wrapper with duplicated content for seamless looping */
.shopping-wrapper {
  display: flex;
  gap: 20px;
  animation: scroll-left 20s linear infinite;
  width: max-content; /* Allows smooth infinite scrolling */
}

/* Shopping Card Styles */
.shopping-card {
  padding: 10px;
  flex: 0 0 auto;
  width: 250px;
  height: 250px;
  background: #1a1a1a;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.shopping-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}

.shopping-card:hover {
  transform: translateY(-10px) scale(1.05);
}

/* Keyframes for seamless scrolling */
@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}



/* Floating price effect */
/* Floating price effect */
.price-popup {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(31, 188, 31); /* Green glowing effect */
  color: white;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  white-space: nowrap;

  /* Trigger the float-and-fade animation */
  animation: price-fade 1s ease-out forwards;
}

/* Price floating animation */
@keyframes price-fade {
  0% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  30% {
    opacity: 1;
    transform: translate(-50%, -20px);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -40px);
  }
}











.home-card-grid h1{
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 20px;
}


.Home-Stats-Poll-Container{
  width: 100%;
    margin-left: 20px;
    width: 100%;
    max-width: 385px;
}

.third-section .Home-Stats-Poll-Container{
  margin-left: 0px;
  margin-right: 20px;
}

.Home-Stats-Poll-Container .question-text h3{
  color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0 10;
}

.Home-Stats-Poll-Container .thanks-for-voting h2{
  font-size: 20px;
  margin-bottom: -20px;
  margin-top: 20px;
  text-align: center;
}

.Home-Stats-Poll-Container .logo-container{
  margin-top: 10px;
}






.feature-item {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;
  justify-content: space-between;
}

.feature-icon {
  width: 36px;
    height: 36px;
}

.feature-icon svg {
  width: 36px;
  height: 36px;
}

.feature-content {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  
}

.feature-content h3 {
  margin: 0;
  font-size: 24px;
  margin-bottom: 10px;
}

.feature-content p {
  margin: 10px 0 0;
  font-size: 18px;
  opacity: 0.8;
}



.start-earning-button {
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  background-color: #0252ff; /* Blue */
  color: white;
  
  border: none;
  border-radius: 10px; /* Fully rounded */
  cursor: pointer;
  width: fit-content;
  transition: background-color 0.2s ease, color 0.2s ease;
  white-space: nowrap;

}

.start-earning-button:hover {
 
  background-color: #ffffff; /* Darker blue */
  color: #121212;
}


.button-icon {
  width: 28px;
  height: 28px;
  transition: transform 0.5s ease-in-out;

}



/* Make the icon spin when hovering over the button */
.start-earning-button:hover .button-icon {
  transform: rotate(360deg);
}

.builder-image{
  margin-top: 40px;
  display: block;
  width: 100%;
  max-width: 1200px;
}

.share-invisible-button{
  background-color: #00d13100;
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 100000;
  top: 130px;
  left: 330px;

}



@keyframes pulse-grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04); /* Slightly grows */
  }
  100% {
    transform: scale(1);
  }
}

.Create-Home-Button {
  position: relative;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 10px 20px;
  background-color: #00d131;
  color: white;
  width: 328px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  bottom: 175px;
  left: 855px;

  /* Add pulsing animation */
  animation: pulse-grow 1s infinite ease-in-out;
}


.Create-Home-Button:hover {
 
  background-color: #ffffff;
  color: #121212;
}


.section-header{
  display: flex;
      justify-content: space-between;
   align-items: flex-start;
}


.partners-image{
  width: 170px;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}


.comments-container{

  display: flex;
  flex-direction: column;

}


.footer {
  background: #1a1a1ab8;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  text-align: center;
  color: white;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 25px;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #121212;
  color: white;
  text-decoration: none;
  transition: background 0.3s ease;
}

.footer-icon svg {
  width: 24px;
  height: 24px;
}

.footer-icon:hover {
  background: #555;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-copyright {
  font-size: 14px;
  margin-bottom: 5px;
}

.footer-patent {
  font-size: 12px;
  color: gray;
}



.footer .easy-builder {
  width: 100%;
    max-width: 500px;
}


.share-section{
  display: flex;
  background-color: #0c0c0c;
}



.subheading-with-logo {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the space between text and logo */
}

.amazon-logo {
  width: 85px; /* Adjust size of the Amazon logo */
  height: auto;
  margin-bottom: -15px;
}




@media (max-width: 768px) {

  .subheading-with-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px; /* Adjust the space between text and logo */
  }
  
  .amazon-logo {
    width: 85px; /* Adjust size of the Amazon logo */
    height: auto;
    margin-bottom: 20px;
  }
  
  .hero-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-family: 'Poppins';
    
  }

  .home-hero-section {
    max-width: 100%;
    text-align: center;
    font-family: 'Poppins';

  }



  
.third-section .Home-Stats-Poll-Container{
  margin-left: 0px;
  margin-right: 0px;
  
}

  .home-hero-section h1 {
    font-size: 2rem; /* Smaller font size for mobile */
    line-height: 1.4;
    margin-bottom: 20px;
    font-family: 'Poppins';

  }

  .home-hero-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-family: 'Poppins';

  }

  .easy-builder {
    flex-direction: column;
    align-items: center;
  }

  .second-section {
    align-items: center;
    background-color: #1a1a1ab8;
    display: flex
;
    justify-content: center;
    min-height: 300px;
    padding-bottom: 4rem;
    padding-top: 2rem;
    width: 100%;
}
  

  .home-live-votes {
    display: flex;
    flex-direction: column;
    max-height: 250px;
    position: relative;
    width: calc(100% - 30px);    
    text-align: left;
}

.share-invisible-button{
  display: none;
}

.home-vote-question {
  color: #b7b7b7;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.Create-Home-Button {
 display: none;

}


.home-poll-container {
  height: auto;
  margin: 280px 0 20px;
}

.section-header {
  align-items: center;
  margin: 0 10px 50px;
  flex-direction: column;
  text-align: center;
}

.second-section h2 {
  font-size: 24px;
}


.start-earning-button {
  margin-top: 20px;
}


.third-section-container .section-header{
  align-items: center;
  margin: 0 10px 50px;
  flex-direction: column-reverse;
  text-align: center;
}


.home-card-grid {
  background-color: #0c0c0c;
  border-radius: 20px;
  display: flex
;
  gap: 5px;
  margin-top: 25px;
  padding-bottom: 20px;
  padding-top: 0px;
  width: 100%;
  flex-direction: column;
  align-content: center;
  align-items: center;
}


.card-grid-item-right {
  display: flex
;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-direction: row;
}

.Home-Stats-Poll-Container {
  margin-left: 0px;
  margin: 15px;
}


.card-grid-item-left {
  border-radius: 10px;
  color: #fff;
  display: flex
;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  padding: 0 15px;
}


.partners-image {
  align-self: center;
  margin-bottom: auto;
  margin-top: auto;
  width: 100px;
  margin: 20px 0px;
}


.third-section h2 {
  font-size: 30px;
  text-align: center;
}

.third-section-container p {
  font-size: 1.25rem;
  text-align: center;
}

.third-section {
  align-items: center;
  display: flex
;
  justify-content: center;
  margin-top: 0;
  min-height: 300px;
  padding-bottom: 3rem;
  padding-top: 3rem;
  width: 100%;
}

.footer .easy-builder {
  margin-left: 0px;
  width: 100%;
}


.home-share-container {
  margin-left: 20px;
  font-size: 12px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 20px;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}


.nav-bar-container {
  padding: 15px 20px;

}



.home-share-arrow {
  color: #fff;
  left: -40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
}



.home-share-box {
  align-items: center;
  background: #1a1a1a;
  border-radius: 8px;
  display: flex
;
  gap: 10px;
  padding: 10px 15px;
  width: 80%;
  margin-left: 20px;
}

}
