.report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.report-page {
  width: 800px; /* Standard document width */
  height: 1100px; /* Standard document height */
  background: #1e1e1e;
  padding: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  color: #e0e0e0;
}

/* Titles */
.report-title {
  font-size: 1.8rem;
  color: #ffffff;
}

.section-title {
  font-size: 1.4rem;
  color: #ffcc00;
  margin-top: 20px;
}

.section-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #cccccc;
}

/* Vote breakdown blocks */
.report-option-group {
  background: #2b2b2b;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.report-option-title {
  font-size: 1.2rem;
  color: #ffffff;
}

/* Confidence bar styling */
.report-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.report-bar-title {
  flex: 1;
  font-size: 0.9rem;
  color: #dddddd;
}

.report-bar-bg {
  flex: 3;
  background: #444;
  border-radius: 6px;
  height: 20px;
  margin: 0 8px;
}

.report-bar {
  height: 100%;
  border-radius: 6px;
}

.report-bar-info {
  flex: 1;
  font-size: 0.9rem;
  color: #e0e0e0;
  text-align: right;
}
