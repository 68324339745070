/* src/App.css */
a{
  text-decoration: none;

}
body {
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #0f0f0f;; /* Dark background for the body */
  color: white;
  font-weight: 400;
  user-select: none; /* Disable text selection */
}

body {
  overflow-y: scroll; /* Ensures scrolling works */
}

::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, Edge */
}


html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: #2c2c2c; /* Track color */
  border-radius: 8px;
}

html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #444444; /* Scroll thumb color */
  border-radius: 8px;
  border: 2px solid #2c2c2c; /* Adds space around the thumb */
}

html::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:hover {
  background-color: #555555; /* Thumb color on hover */
}


.thanks-for-voting h2{
  margin-bottom: -10px;
}


.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-weight: 400;
}



.App-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spread items across the header */
  background-color: #212121;
  padding: 10px 10px;
  overflow-x: hidden;
}


.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.App-nav {
  display: flex;
  margin-left: 20px;
  overflow-x: hidden;
}

/* The default styles for the desktop nav */
.opinari-footer-nav {
  display: flex;
  gap: 20px;
  margin-left: 20px; /* Space between logo and nav */
}

.opinari-footer-nav a {
  margin-right: 15px;
  text-decoration: none;
  color: white;
  padding: 5px;
}

.opinari-footer-nav a:hover {
  margin-right: 15px;
  text-decoration: none;
  color: rgb(32, 32, 32);
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.auth-buttons {
  margin-left: auto; /* Keeps buttons to the right on desktop */
  display: flex;
  align-items: center;
}

/* Hide the burger menu and mobile nav by default (on desktop) */
.burger-menu,
.mobile-nav {
  display: none;
}

.subscribed-title {
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 15px;
}


.App-logo {
  margin-right: 20px; /* Adds space between the logo and navigation */
  vertical-align: middle; /* Prevents extra space below the image */
}

/* Hide the burger menu on larger screens */
.burger-menu {
  display: none;
}


.profile-placeholder-add-button {
  -webkit-appearance: none;
    background-color: #363636;
    border-radius: 5px;
    color: #ffffff;
    padding: 6px 30px 10px;
    text-decoration: none;
    font-size: 14px;
  
}



.App-logo {
  width: 100px;
  animation: none!important; /* Override the default animation */
}

.App-nav {
  display: flex;
}

.captcha-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it stays on top */
}

.captcha-container {
  z-index: 1001; /* Ensure CAPTCHA is on top of the overlay */
}


.App-button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.tools-button {
  padding: 10px 20px;
  background-color: #17db14;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
}

.dashboard-tools-button {
  padding: 10px 20px;
  background-color: #17db14;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.App-main {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0px;
}

.with-navbar {
  padding-bottom: 70px; /* Adjust based on the height of your navbar */
}

.without-navbar {
  padding-bottom: 0px; /* No padding when navbar is hidden */
}

.App-link {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  text-decoration: none;
}

.color-picker-container {
  text-align: center;
  background-color: #191919; /* Dark theme box */
  padding:10px;
  padding-top: 30px;
  padding-bottom: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0px;
  margin-top: 0px;
  align-self: center;
}

.placeholder-color-picker-container {
  background-color: #191919;
    border-radius: 10px;
    box-shadow: 5px -8px 8px #00000047;
    /* margin: 20px 0 0; */
    padding: 30px 10px 25px;
    text-align: center;
    /* margin-top: 0px; */
    margin-bottom: -90px;
    border: solid 2px #535353;
}

.color-wheel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.color-wheel-container {
  width: 100%;
  padding-top: 100%; /* This maintains the aspect ratio */
  position: relative;
}

.color-wheel-container .w-color-wheel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.question-text h3 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Ensure the desired weight is applied */
  margin-left: 10px;
  margin-right: 10px;
}

.placeholder-question-text h3 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Ensure the desired weight is applied */
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
}

.opinion-text h2 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Ensure the desired weight is applied */
  white-space: nowrap;
}


h1{
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Ensure the desired weight is applied */
  margin-bottom: 5px;
  margin-top: 5px;
}

.confidence-label {
  text-align: center; /* Center-align the text */
  color: #ffffff; /* Adjust color as needed */
  margin-top: 10px;
  font-weight: 600;
    font-size: 16px;
}

.option-percentage{
  font-weight: 600;
  font-size: 16px;
}

.submit-button {
  position: absolute;
  padding: 10px 20px;
  background-color: #212121; /* Grey background */
  color: white; /* White text */
  border: 2px solid white; /* White border */
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px; /* Larger text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow to the button */
  transform: translate(-50%, -50%); /* Center the button at the mouse position */
  z-index: 1; /* Ensure the button is above the pseudo-element */
  max-width: 300px;
}

.redirect-button {
  position: absolute;
  padding: 10px 20px;
  background-color: #212121; /* Grey background */
  color: white; /* White text */
  border: 2px solid white; /* White border */
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px; /* Larger text */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow to the button */
  transform: translate(-50%, -50%); /* Center the button at the mouse position */
  z-index: 1; /* Ensure the button is above the pseudo-element */
  min-width: 150px;
  max-width: 200px;
  text-decoration: none;
}

.skip-button {
    color: #fff;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    position: absolute;
    transform: translate(-50%, -0%);
    z-index: 1;
    text-decoration: underline;
}

.inactive-skip-button {
  color: #fff;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 10px 20px;
    text-decoration: underline;
    z-index: 1;
}

.color-bar-wrapper {
  position: relative;
  width: 80%;
  margin: 20px auto 0; /* Add top margin and center the bar */
}

.color-bar-container {
  margin: 20px auto 0; /* Add top margin and center the bar */
  height: 20px; /* Make the bar taller */
  border-radius: 15px; /* Round the edges */
  overflow: hidden;
  display: flex;
  /* background-color: #3b3b3b; */
}

.color-segment {
  height: 100%;
  position: relative;
  transition: opacity 0.2s ease-in-out
}


.color-segment.hovered {
opacity: 0.6;}

.vote-counts {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Space the text blocks equally */
  width: 100%;
}

.vote-count {
  color: white;
  font-size: 12px;
  white-space: nowrap;
  padding: 5px; /* Add padding for better spacing */
  text-align: center; /* Center the text */
}


.confidence-counts {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Space the text blocks equally */
  width: 100%;
}

.confidence-bar-wrapper {
  position: relative;
  width: 80%;
  margin: 20px auto 0;
}

.confidence-bar-container {
  margin: 0px auto 0; /* Add top margin and center the bar */
  height: 20px; /* Make the bar taller */
  border-radius: 15px; /* Round the edges */
  overflow: hidden;
  display: flex;
  margin-bottom: 10px;
}

.confidence-segment {
  opacity: 1;
  height: 100%;
  position: relative;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
cursor: pointer;
}

.confidence-segment:not(:last-child) {
  border-right: 1px solid #191919;
}



.confidence-segment.hovered {
  opacity: 1;
}

.confidence-vote-counts {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
}

.confidence-vote-count {
  text-align: center;
}

.confidence-labels-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.confidence-label {
  text-align: center;
  display: flex;
    align-items: center;
    gap: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;
}
.confidence-vote-counts.single {
  justify-content: center;
}



.vote-count div:first-child {
  font-size: 14px; /* Larger font size for the percentage */
}

.subscription-button {
  border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    padding: 4px 8px;
    text-align: center;
    margin-bottom: -2px;
}


.Feed {
  padding: 20px;
  height: 100vh;
}

.Feed .poll-success-modal-overlay{
  align-items: flex-start;
    background: #000000cf;
    display: flex
;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999999;
    padding-top: 40px;
}

.Polls-list {
  display: flex;
  flex-direction: column;
}

.Polls-list .color-picker-container{
  height: 100%;
}


.AddPollButton {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}


.NewPollForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.NewPollForm input {
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
}

.NewPollForm button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.Poll-create {
  margin-bottom: 20px;
  color: white; /* Ensure text is white */
}

.Poll-create input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #444; /* Darker background for input */
  color: white; /* Ensure text is white */
}

.Poll-options {
  margin-bottom: 10px;
}

.Poll-options label {
  display: block;
  margin-bottom: 5px;
  color: white; /* Ensure text is white */
}

.Poll-create button {
  padding: 10px 20px;
  background-color: #61dafb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.Poll-options input[type="radio"] {
  margin-right: 5px;
}

.Poll-create input[type="url"], .Poll-create textarea {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #444; /* Darker background for input */
  color: white; /* Ensure text is white */
  text-align: center;
}

.Poll-create textarea {
  resize: none; /* Prevent resizing */
  height: 60px; /* Adjust height as needed */
  text-align: center;
}

.Add-button {
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
}

.Collection-Add-button {
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  margin-top: 20px;
}

.ConnectedPoll-box-title {
  font-size: 22px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 0px;
}

.ConnectedPollWrapper {
  padding: 20px;
  margin: 0 auto; /* Centering the content */
  max-width: 800px; /* Adjust this value as needed */
}

.ConnectedPollWrapper {
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;}

.ConnectedPoll {
  position: relative;
}

.ConnectedPoll-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
}

.ConnectedPoll-item.active {
  position: relative;
  opacity: 1;
  pointer-events: auto;
}

.ConnectedPoll-item:not(.active) {
  opacity: 0; /* Make non-active polls invisible */
  pointer-events: none; /* Prevent interaction with non-active polls */
}

.ConnectedPoll-item.fade-out {
  opacity: 0;
  z-index: 9;
}




.next-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.ConnectedPoll-item.active .next-arrow {
  opacity: 1;
}

.ConnectedPoll-item.fade-out .next-arrow {
  opacity: 0;
}











.Profile-Add-button {
  margin-left: 0px;
  padding: 10px 30px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

.total-votes {
  margin-top: 20px !important;
  color: grey;
}

.gender-stats {
  margin-top: 20px;
}

.gender-stats h4,
.confidence-stats h4 {
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

.dropdown-toggle {
  background: #2f2f2f;
    border-radius: 5px;
    color: #fff !important;
    cursor: pointer;
    margin-top: 20px;
    padding: 4px;
    width: 140px;
    font-size: 14px;
}




.dropdown-toggle-wrapper{
  display: flex
;
    flex-direction: column;
    align-items: center;
}
.dropdown-content {
  color: grey !important;
}

.poll-dropdown-wrapper {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  padding: 10px 0;
}

.poll-dropdown-button {
  width: 100%;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px; /* Add padding-right to push the arrow to the far right */
}



.poll-dropdown-content {
  display: none;
  background-color: #191919;
  padding: 10px;
  padding-bottom: 0px;
    padding-top: 5px;

}

.poll-dropdown-wrapper.active .poll-dropdown-content {
  display: block;
}


.color-picker-container .header {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  margin-top: 20px;
}

.color-picker-container .logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 130px;
  max-height: 160px;
}

.color-picker-container .share-icon {
  position: absolute;
  right: 0;
  margin-right: 12px;
  color: grey;
}

.color-picker-container .analytics-icon {
  position: absolute;
  left: 0;
  margin-left: 12px;
  color: grey;
}

.color-picker-container .poll-dropdown-icon {
  position: absolute;
  left: 0;
  margin-left: 12px;
  color: grey;
}


.fa-2x {
  font-size: 1.4em !important;
}

.Profile-follow-icon {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  font-size: 1.4em; /* Same size as the settings icon */
  cursor: pointer;
  color: #808080; /* Default color */
  margin-top: 5px;
    margin-left: 5px;
}

.hidden {
  display: none;
}

.Search-input {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  font-size: 16px;
  margin-top: 20px;
}



.Search-input::placeholder {
  color: #aaa;
}

.Feed-header {
  width: 100%; /* Ensure it takes the full width */
  overflow: visible; /* Allow overflow to be visible for debugging */
  padding: 10px;
  box-sizing: border-box; /* Include padding in the width */
}

.Modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000007a;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the modal is on top of everything */
  overflow-y: auto; /* Enable scrolling */
}

.Modal-content {
  background: #444;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  max-height: 80vh; /* Limit the height */
  overflow-y: auto; /* Enable scrolling inside the modal */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Poll-options label,
.Poll-category label {
  display: block;
  margin-bottom: 10px;
}

.Poll-category select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #212121;
  color: white;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Poll-question-input {
  width: 70%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  font-size: 16px;
}

.Poll-question-input::placeholder {
  color: #aaa;
  text-align: center;
  
}

.collection-name-input {
  background-color: #212121;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    padding: 10px;
    width: 85%;
    text-align: center;
}

.collection-name-input::placeholder {
  color: #aaa;
  text-align: center;
  
}

h2{
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-top: 0px;
}

.Create-button, .Cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.Create-button {
  background-color: #14ff0b;
  color: white;
}

.Cancel-button {
  background-color: #1c1c1c;
  color: white;
  padding: 5px 10px;
  margin-top: 15px;
}

h2 {
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  margin-top: 0px;
}

.Modal-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center;
}


.Modal-content {
  background: #444;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.Auth-input {
  margin: 10px 0;
  padding: 10px;
  width: 80%; /* Adjust this to control the width of the input fields */
  max-width: 400px; /* Ensure a max-width for larger screens */
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #212121;
  color: white;
}

.embed-code-textarea {
  width: 100%;
  background-color: #444;
  color: white;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px;
  resize: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}


.Auth-input::placeholder {
  color: #aaa;
}

.Auth-error {
  color: red;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


.Auth-button {
  padding: 10px 20px;
  background-color: #212121;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  margin-top: 10px;
}

.temporary-poll-warning{
  margin-bottom: 15px;
  color: rgb(255, 204, 0);
}




.Auth-modal-button {
  padding: 10px 20px;
  background-color: #00d131;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  margin-top: 10px;
}

.Show-more-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Show-more-button:hover {
  background-color: #666;
}

.Search-category {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  font-size: 16px;
}

.Category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.Category-button {
  padding: 5px 8px;
  margin: 5px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.Category-button.selected {
  background-color: #007eff;
  color: rgb(255, 255, 255);
}

/* Add these styles */
.Search-type-toggle {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.Search-type-toggle label {
  margin: 0 10px;
  cursor: pointer;
}

.Search-type-toggle input[type="radio"] {
  display: none;
}

.Search-type-toggle input[type="radio"]:checked + span {
  background-color: #007eff;
    color: #ffffff;
}

.Search-type-toggle span {
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.ScrollingPolls {
  overflow: hidden;
  position: relative;
  width: 100%; /* Full width */
  margin-top: 35px;
}

.ScrollingPolls-container {
  display: flex;
  flex-direction: row;
  height: 100%; /* Make the container's height relative to the parent */
  overflow-x: hidden; /* Hide horizontal scroll bar */
  overflow-y: hidden; /* Hide vertical scroll bar */
  animation: scroll 20s linear infinite;
}

.ScrollingPolls-item {
  flex: 0 0 auto;
  width: 250px; /* Adjust the width as needed */
  margin: 0 10px;
}

.PollFake-link {
  text-decoration: none;
  color: inherit;
}

.PollFake {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: scale(1);
  background-color: #333333;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  padding-top: 5px;
}

.PollFake:hover {
  transform: scale(1.05); /* Slightly enlarges the component */
}

.PollFake-header h3 {
  text-align: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

h3{
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Ensure the desired weight is applied */
  margin-left: 10px;
  margin-right: 10px;

}


.PollFake-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PollFake-colorWheel {
  width: 100%;
  max-width: 175px; /* Adjust size as needed */
  margin: 10px 0;
  margin-bottom: 0px;
}

/* Specific styles for ColorBar within PollFake */
/* Specific styles for ColorBar within PollFake */
.PollFake .ColorBar-FakePoll .color-bar-container {
  height: 12px !important; /* Example: Adjust height */
  margin-top: 0px;
}

.ColorBar-FakePoll .vote-count {
  font-size: 10px; /* Adjust the font size for the entire vote count block */
}

.ColorBar-FakePoll .vote-count div:first-child {
  font-size: 10px; /* Adjust the font size for the percentage text */
}

.ColorBar-FakePoll .vote-count div:last-child {
  font-size: 10px; /* Adjust the font size for the label text */
}

/* ConfidenceBar Styles */
.confidence-bar-wrapper {
  position: relative;
  width: 80%;
  margin: 20px auto 0; /* Add top margin and center the bar */
  margin-top: 0px;
}

.confidence-bar-container {
  margin: 20px auto 0;
    height: 20px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    margin-top: 10px;
  
}

.confidence-segment {
  height: 100%;
}

.confidence-vote-counts {
  margin-top: 10px;
  display: flex;
  justify-content: space-between; /* Space the text blocks equally */
  width: 100%;
}

.confidence-vote-count {
  color: white;
  font-size: 12px;
  white-space: nowrap;
  padding: 5px; /* Add padding for better spacing */
  text-align: center; /* Center the text */
}

.analytics-icon {
  cursor: pointer; /* Add this to show a pointer cursor on hover */
}

.share-icon {
  cursor: pointer; /* Add this to show a pointer cursor on hover */
}

.poll-dropdown-icon{
  cursor: pointer;
}



.PollFake-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 15px;
  max-height: 80px;
}

.honeypot-field {
  display: none;
}

.PollFake-logoImage {
  border-radius: 5px;
    max-height: 100px;
    max-width: 100px;
}




/* Initial state of the button */
/* Initial state of the button */
.build-yours-button {
  background-color: #0075ff;
  transform: scale(1); /* Normal size */
  transition: transform 0.2s ease-in-out; /* Smooth scaling animation */
}

/* Pop out animation state */
.build-yours-button.animate-pop {
  transform: scale(1.05); /* Pop out to 1.1 scale */
  transition: transform 0.2s ease-in-out; /* Smooth scale out and back */
}

/* Keyframe animation to scale back to 1 */
@keyframes scale-back {
  100% {
    transform: scale(1); /* Return to normal size */
  }
}

.build-yours-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  margin-left: 5px;
}
.truncate-text {
  white-space: nowrap; /* Prevent the text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add the ellipsis (...) when the text overflows */
  max-width: 100%; /* Ensure it takes up available space within its container */
}


.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: #212121;
  padding: 10px;
  border-radius: 5px;
  z-index: 100000;
}

.menu span {
  color: white;
  margin-bottom: 10px;
}

.menu .App-button {
  width: 100%;
}

.Poll-item {
  margin-bottom: 20px;
}






.wrapper-vertical {
  width: 290px; /* Adjust based on the width of your FakePoll component */
  height: 100%; /* Make it take full height */
  max-height: 100%;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollUp {
  to {
    top: -380px; /* Adjust based on the height of your items */
  }
}

.item-vertical {
  width: 100%; /* Adjust to fit the width of FakePoll component */
  height: 380px; /* Adjust based on the height of your FakePoll component */
  position: absolute;
  top: max(calc(380px * 8), 100%); /* Adjust based on the height of your items */
  animation-name: scrollUp;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-vertical1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item-vertical2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item-vertical3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item-vertical4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item-vertical5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item-vertical6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item-vertical7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item-vertical8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}

.wrapper-vertical-reverse {
  width: 290px; /* Match the width of the vertical scroll component */
  height: 100%;
  max-height: 100%;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(
    to top, /* Adjust for reverse scrolling */
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollDown {
  from {
    top: -100%; /* Start off-screen at the top */
  }
  to {
    top: calc(380px * 8); /* Adjust based on the height of your items */
  }
}

.item-vertical-reverse {
  width: 100%; /* Adjust to fit the width of FakePoll component */
  top: max(calc(380px * 8), 100%); /* Adjust based on the height of your items */
  position: absolute;
  top: -100%; /* Start off-screen at the top */
  animation-name: scrollDown;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-vertical-reverse1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item-vertical-reverse2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item-vertical-reverse3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item-vertical-reverse4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item-vertical-reverse5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item-vertical-reverse6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item-vertical-reverse7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item-vertical-reverse8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}



.wrapper {
  padding-top: 20px;
  width: 100%;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 170px; /* Adjust the height to fit the FakePoll components */
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollLeft {
  to {
    left: -260px; /* Adjust based on the width of your items */
  }
}

.item {
  width: 290px; /* Adjust based on the width of your FakePoll component */
  height: 100%; /* Adjust to fit the FakePoll component */
  position: absolute;
  left: max(calc(260px * 8), 100%); /* Adjust based on the width of your items */
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}



.wrapper-reverse {
  width: 100%;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 450px; /* Adjust the height to fit the FakePoll components */
  overflow: hidden;
  mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  
}

@keyframes scrollRight {
  to {
    right: -260px; /* Adjust based on the width of your items */
  }
}

.item-reverse {
  width: 290px; /* Adjust based on the width of your FakePoll component */
  height: 100%; /* Adjust to fit the FakePoll component */
  position: absolute;
  right: max(calc(260px * 8), 100%); /* Adjust based on the width of your items */
  animation-name: scrollRight;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.item-reverse1 {
  animation-delay: calc(30s / 8 * (8 - 1) * -1);
}

.item-reverse2 {
  animation-delay: calc(30s / 8 * (8 - 2) * -1);
}

.item-reverse3 {
  animation-delay: calc(30s / 8 * (8 - 3) * -1);
}

.item-reverse4 {
  animation-delay: calc(30s / 8 * (8 - 4) * -1);
}

.item-reverse5 {
  animation-delay: calc(30s / 8 * (8 - 5) * -1);
}

.item-reverse6 {
  animation-delay: calc(30s / 8 * (8 - 6) * -1);
}

.item-reverse7 {
  animation-delay: calc(30s / 8 * (8 - 7) * -1);
}

.item-reverse8 {
  animation-delay: calc(30s / 8 * (8 - 8) * -1);
}


.ConnectedPollCompleteOverlay {
  align-items: center;
  animation: fadeIn 0.5s forwards, fadeOut 0.5s forwards 1.5s; /* Adjust timings */
  background-color: #000c;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  border-radius: 15px;
}

.ConnectedPollCompleteMessage {
  color: white;
  font-size: 1.5rem;
  text-align: center;
}

/* Keyframes for the fade-in effect */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Keyframes for the fade-out effect */
@keyframes fadeOut {
  to {
    opacity: 0;
  }
}



.Poll-placeholder-container {
  margin-bottom: 20px;
    max-width: 90%;
    margin: 20px;
    margin-top: 30px;

}




.Poll-placeholder-image {
  margin-bottom: 20px;
    max-width: 90%;
    display: flex;
    margin: 20px;
    margin-top: 30px;


}

.opinari-footer-social {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.opinari-footer-social a {
  color: #ffffff;
  font-size: 24px;
  margin: 0 10px;
  transition: color 0.3s;
}

.opinari-footer-social a:hover {
  color: #0066ff; /* Change to desired hover color */
}

.Poll-placeholder-container p {
  color: #fff;
  font-size: 1.2rem;
}




.creator-username {
  margin-top: 15px;
  text-align: center;
}

.creator-link {
  color: darkgrey;
  text-decoration: none;
}

.creator-link:hover {
  color: grey;
}


.delete-poll {
  margin-top: 10px;
    position: relative;
    z-index: 10000;
}

.delete-poll button {
  background-color: #ff4d4d; /* Red color for delete button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.connected-box-delete-poll {
  background-color: #ff4d4d; /* Red color for delete button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 85px;
  margin-left: 5px;
}

.ConnectedPoll-box-share-icon{
  color: grey;
    font-size: 1.4em;
    margin-right: 20px;
    margin-top: 5px;
    position: absolute;
    right: 10px;

}
.ConnectedPoll-box-view-icon{
  color: grey;
    font-size: 1.4em;
    margin-left: 20px;
    margin-top: 5px;
    position: absolute;
    left: 10px;

}


.connected-box-edit-button {
  background-color: #7a7f79; /* Red color for delete button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 85px;
  margin-right: 5px;
}

.poll-number-display {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}


.confirm-delete{
  position: relative;
    z-index: 10000;
}


.confirm-delete p {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.confirm-delete button {
  background-color: #ff4d4d;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    padding: 8px 15px;
    transition: background-color .3s;
    margin: 10px;
  
}

.user-info {
  align-items: center;
    display: flex;
    margin-right: 5px !important;
}

.username {
  font-size: 14px;
    margin-left: 5px !important;
}

.online-indicator {
  width: 10px;
  height: 10px;
  background-color: #00ff1a;
  border-radius: 50%;
  margin-left: 2.5px; /* Adjust as necessary */
}

.username-link {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.App-link-no-style {
  text-decoration: none;
  color: inherit;
  margin-right: 5px; /* Adjust as necessary */
}

.color-bar-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.color-bar-wrapper.hidden {
  opacity: 0;
  pointer-events: none;
}

.color-bar-wrapper.visible {
  opacity: 1;
}

.confidence-bar-wrapper {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.confidence-bar-wrapper.hidden {
  opacity: 0;
  pointer-events: none;
}

.confidence-bar-wrapper.visible {
  opacity: 1;
}






.edit-button {
  background: none;
  border: none;
  color: #808080;
  cursor: pointer;
  font-size: 20px;
}

.edit-button:focus {
  outline: none;
}

.bio-section {
  margin-top: 0px;
  text-align: center;
}

.bio-section p {
  color: #fff;
    font-size: 16px;
    margin: 0;
    padding: 10px;
    padding-top: 0px;
}


.vote-scatter-plot {
  margin-top: 10px;
}

.recharts-dot {
  r: 6 !important; /* Increase the dot size */
  fill-opacity: 1 !important; /* Ensure full color opacity */
}

.stats-section {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.stats-section span {
  display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
    margin-left: 10px;
}

.stats-section b {
  font-weight: 600;
  margin-right: 5px;
}


.embed-code-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-family: monospace;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}






.Profile {
  text-align: center;
}

.Profile-card {
  background-color: #212121;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
  position: relative; /* Added for positioning the settings icon */
}

.Profile-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #4f4f4f;
  border-radius: 0px;
}

.Profile-image-container {
  position: relative;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile-uploading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.Profile-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  margin-bottom: 5px;
}

.Profile-username {
  font-size: 18px;
  text-align: left;
  margin-bottom: 0px;
}

.Profile-stats {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.Profile-stat {
  text-align: left;
  margin-right: 25px;
}

.Profile-stat b {
  display: block;
  font-size: 1.2em;
}

.Profile-bio {
  margin-bottom: 0px;
  margin-top: 10px;
}

.Profile-edit-bio {
  margin-top: 20px;
}

.Profile-polls {
  margin: 20px;
}


.Profile-settings-icon {
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  color: grey;
  font-size: 1.4em; /* Adjust as needed */
  left: 10px;
    top: 10px;
}

.Profile-share-icon {
  position: absolute;
  margin-right: 5px;
  margin-top: 5px;
  color: grey;
  font-size: 1.4em; /* Adjust as needed */
  right: 10px;
    top: 10px;
}

.Public-profile-share-icon {
  position: absolute;
  margin-right: 5px;
  margin-top: 5px;
  color: grey;
  font-size: 1.4em; /* Adjust as needed */
  right: 10px;
    top: 10px;
}




@media (min-width: 768px) {
  .Profile-card {
    padding: 40px;
  }
}

.Search-container {
  display: flex;
  align-items: baseline;
  width: 100%; /* Make the container take the full width */
}

.Search-input {
  flex: 1; /* Make the input take all the available space */
  margin-right: 8px; /* Add some space between the input and the icon */
  margin-left: 30px;
  text-align: center;
}

.Filter-icon {
  cursor: pointer;
  color: #808080; /* Make the icon gray */
  font-size: 1.4em;
  transform: translate(0px, 5px);
    margin-left: 5px;
}

/* EmbeddedPoll.css */
.EmbedPoll {
  padding: 0;
  margin: 0;
  background-color: transparent;
  overflow: hidden;
}

.embed-poll-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 800px; /* Set the minimum height */
  overflow: hidden;
}

.embed-wrapper {
  min-height: 800px; /* Set a minimum height */
  width: 450px; /* Fixed width */
  overflow: hidden; /* Ensure no scrollbars */
}


.embed-poll-container {
  width: 450px; /* Fixed width */
  max-width: 100%;
  height: auto;
}



body.embed-poll-body {
  background-color: transparent !important;
}

.Forgot-password-button {
  background: none;
  border: none;
  color: grey;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-top: 15px;
}

.Forgot-password-button:hover {
  color: darkgrey;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.fade-in.show {
  opacity: 1;
}

.tooltip {
  background-color: #212121;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
  padding: 10px;
  border: solid 2px white;
}

.tooltip-opinion {
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 400;
}

.tooltip-timestamp {
  font-size: 12px;
  font-family: 'Poppins';
  font-weight: 400;
}

.tooltip-wrapper-confidence {
  position: absolute;
  top: -20px; /* Adjust this value to move the tooltip upwards */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
  transform: translate(0px, 0px) !important;
  overflow: hidden;
}

.tooltip-wrapper {
  z-index: 10000;
  position: absolute;
  top: -100px; /* Adjust this value to move the tooltip upwards */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none; /* Ensure the tooltip does not interfere with mouse events */
  transform: translate(0px, 0px) !important;
  overflow: hidden;
}







.Profile-tab-button {
  all: unset; /* Removes all inherited and default styles */
  display: inline-block; /* Ensures the button behaves correctly */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  -webkit-appearance: none; /* Removes default appearance in WebKit browsers */
  -moz-appearance: none; /* Removes default appearance in Firefox */
  appearance: none; /* Removes default appearance in other browsers */
}

/* Apply your custom styles */
.Profile-tab-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
}

.Profile-tab-button {
  flex: 1;
  padding: 15px 0;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  background-color: #212121;
  color: white;
  border: none;
  border-top: 1px solid #444;
  border-right: 1px solid #444;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  margin: 0;
}

.Profile-filter-tabs {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.Profile-filter-tab-button {
  flex: 1;
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  background-color: #212121;
  color: white;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  margin: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.Profile-filter-tab-button:last-child {
  border-right: none;
}

/* Specific active state colors */
.Profile-filter-tab-button.active.active-tab {
  background-color: #17db14; /* Green for Active */
}

.Profile-filter-tab-button.active.scheduled-tab {
  background-color: #ffb100; /* Yellow for Scheduled */
}

.inactive-poll-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}



.inactive-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: white;
  font-family: 'Poppins', sans-serif;
  padding: 10px;
}


.countdown-text {
  font-size: 18px;
  margin-bottom: 8px;
}

.countdown-timer {
  font-size: 24px;
  margin-bottom: 8px;
}

.start-date-text {
  font-size: 14px;
}

.animated-border {
  animation: animate-border-hue 10s linear infinite;
  border-radius: 50%;  /* Ensure the border follows the circle shape */
}

@keyframes animate-border-hue {
  0% {
    border-color: rgb(255, 0, 0);
  }
  16% {
    border-color: rgb(255, 255, 0);
  }
  33% {
    border-color: rgb(0, 255, 0);
  }
  50% {
    border-color: cyan;
  }
  66% {
    border-color: blue;
  }
  83% {
    border-color: magenta;
  }
  100% {
    border-color: red;
  }
}





.Profile-tab-button:last-child {
  border-right: none;
}

.Profile-tab-button.active {
  background-color: #0075ff;
}

.Profile-Add-button {
  margin-top: 10px;
}

.poll-link {
  text-decoration: underline;
  color: #ffffff; /* Adjust this color to match your design */
}

.logo-container {
  display: inline-block;
  border-radius: 8px;
}

.logo-container.filled {
  display: flex;
  background-color: white;
  padding: 0px;
}

.fake-logo-container {
  display: inline-block;
  border-radius: 5px;
  width: 80px;
}

.fake-logo-container.filled {
  background-color: white;
  padding: 8px;
  padding-bottom: 0px;
  padding-top: 4px;
  padding-top: 7px;
}

.save-settings-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  background: #00d131;
  color: #fff;
  width: 100%;
  margin-top: 10px;
}

.Feed-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 0px;
}

.Arrow-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #808080;
  padding: 0 10px;
}

.Arrow-button:focus {
  outline: none;
}

.Arrow-button:focus {
  outline: none;
}



/* ConnectedPoll.css */

.ConnectedPoll-box {
  background-color: #1e1e1e;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    margin: 10px;
    padding: 15px;
    padding-bottom: 20px;
    margin-top: 25px;
}

.ConnectedPoll-question {
  font-size: 16px;
  margin-bottom: 10px;
}


.plain-page-header h2{
  font-size: 34px;
  margin-bottom: 0px;
}

.plain-page-header{
  margin-top: 25px;
  margin-bottom: 0px;
}



.image-modal-img{

  width: 100%;
}


.image-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
}


.loading-bar-container {
  width: 100%;
  background-color: #4d4d4d;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.loading-bar {
  height: 10px;
  background-color: #57ff5d;
  transition: width 0.5s ease;
}

.loading-message {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}

/* Individual poll items */
.Connected-Poll-placeholder-item {
  border-radius: 8px;
  background-color: #212121;
  box-shadow: 0 4px 8px #0003;
  width: 100%;
  margin-bottom: 20px;
}

.App-SignUp-Button {
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  background-color: white; /* White background */
  color: black; /* Black text */
  border: 1px solid black; /* Black border */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold text */
  text-align: center; /* Center text */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.App-SignUp-Button:hover {
  background-color: black; /* Change background color on hover */
  color: white; /* Change text color on hover */
}

.App-LogIn-Button {
  display: inline-block;
  padding: 0.5rem 1rem; /* Adjust padding as needed */
  background-color: transparent; /* Transparent background */
  color: white; /* White text */
  border: 1px solid white; /* White border */
  border-radius: 4px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold text */
  text-align: center; /* Center text */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  margin-left: 1rem; /* Add some space between buttons */
}

.App-LogIn-Button:hover {
  background-color: white; /* Change background color on hover */
  color: black; /* Change text color on hover */
}


.app-opinari-btn {
  padding: 0.4rem 1rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.app-opinari-btn-primary {
  background-color: var(--accent-color);
  color: var(--button-text-color);
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
}

.app-opinari-btn-primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #34e92a, #34e92a);
  transition: left 0.3s ease;
  z-index: 1;
}

.app-opinari-btn-primary:hover::before {
  left: 0;
}

.app-opinari-btn-primary:hover {
  color: #ffffff;
  transform: scale(1.05);
}

.app-opinari-btn-primary span {
  position: relative;
  z-index: 2;
  display: block;
}

.app-opinari-btn-secondary {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--text-color);
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
  overflow: hidden;
}


.color-picker-container .preview-header {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
  margin-top: 30px;
}

.app-opinari-btn-secondary::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #0080ff;
  transition: width 0.4s ease;
  z-index: 1;
}

.app-opinari-btn-secondary:hover::before {
  width: 100%;
}

.app-opinari-btn-secondary span {
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}

.app-opinari-btn-secondary:hover span {
  color: #ffffff;
}

.app-opinari-btn-secondary:hover {
  transform: scale(1.05);
}



.Profiles-list {
  display: block;
  gap: 20px;
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box; /* Ensure padding doesn't affect the grid width */
}

@keyframes pollUserFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Optional: adds a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.poll-user-fade-in {
  animation: pollUserFadeIn 0.5s ease-in-out forwards; /* Use 'forwards' to keep the end state */
  opacity: 1; /* Ensures that the end state is fully visible */
}




@media screen and (min-width: 768px) {


  .color-picker-container .preview-header {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .Profiles-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect the grid width */
    padding-top: 5px;
  }


  .Poll-item {
    margin: 0 auto;   /* Center the poll items */
  }

  .ConnectedPoll-box {
    background-color: #1e1e1e;
      /* border: 1px solid #ccc; */
      border-radius: 10px;
      margin: 10px;
      padding: 15px;
      padding-bottom: 20px;
      margin-top: 25px;
      position: relative;
  }

  .placeholder-color-picker-container {
    border: 2px solid #535353;
    box-shadow: 5px -8px 8px #00000047;
    margin-bottom: 10px;
  }

  /* Individual poll items */
.Connected-Poll-placeholder-item {
  border-radius: 8px;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  background-color: #00000000;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0);
  margin-bottom: 0px;
}

  /* Container for the polls */
.Polls-list {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  gap: 20px; /* Space between items */
  padding: 20px;
  box-sizing: border-box;
}
.Connected-poll-list {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  gap: 20px; /* Space between items */
  padding: 20px;
  box-sizing: border-box;
}

.Profile-polls {
  display: grid;
  grid-template-columns: 1fr; /* Default to single column */
  gap: 20px; /* Space between items */
  padding: 20px;
  box-sizing: border-box;
}

/* Individual poll items */
.Poll-item {
  border-radius: 8px;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.color-picker-container {
  text-align: center;
  background-color: #191919; /* Dark theme box */
  padding:10px;
  padding-top: 30px;
  padding-bottom: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0);
  margin: 0px;
  margin-top: 0px;
  align-self: center;
  padding-top: 10px;
}

.Search-container {
  width: 100%;
  display: block;
}
}



@media (min-width: 600px) {
  .Polls-list, .Connected-poll-list, .Profile-polls {
    grid-template-columns: repeat(1, 1fr); /* Two columns */
  }

  .StandalonePollWrapper{
    display: inline-block;
  }

  .StandalonePoll{
    width: 400px;
  max-width: 500px;
  }
}

@media (min-width: 900px) {
  .Polls-list, .Connected-poll-list, .Profile-polls {
    grid-template-columns: repeat(2, 1fr); /* Three columns */
  }
  .StandalonePollWrapper{
    display: inline-block;
  }
  .StandalonePoll{
    width: 400px;
    max-width: 500px;
    }
}

@media (min-width: 1200px) {
  .Polls-list, .Connected-poll-list, .Profile-polls {
    grid-template-columns: repeat(3, 1fr); /* Four columns */
  }
  .StandalonePollWrapper{
    display: inline-block;
  }
  .StandalonePoll{
    width: 400px;
    max-width: 500px;
    }
}

@media (min-width: 2000px) {
  .Polls-list, .Connected-poll-list, .Profile-polls {
    grid-template-columns: repeat(4, 1fr); /* Four columns */
  }
  .StandalonePollWrapper{
    display: inline-block;
  }
  .StandalonePoll{
    width: 400px;
    max-width: 500px;
    }
}



@media (min-width: 900px) { /* Adjust for medium screens */
  .Polls-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 20px; /* Space between items */
    padding: 20px;
    box-sizing: border-box;
  }
}

@media (min-width: 1200px) { /* Adjust for larger screens */
  .Polls-list {
    grid-template-columns: repeat(3, 1fr); /* Three columns */
  }
}

@media (min-width: 2000px) { /* Adjust for very large screens */
  .Polls-list {
    grid-template-columns: repeat(4, 1fr); /* Four columns */
  }
}


.infinite-scroll-component {
  overflow-x: hidden;
}



/* Wrapper for the polls */
.wrapper {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust min width */
  gap: 20px;
}

/* Item container */
.item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* PollFake component with fixed height */
.PollFake {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #333333;
  border-radius: 10px;
  padding: 15px;
  padding-top: 5px;
  height: 400px; /* Fixed height to ensure equal size */
  overflow: hidden; /* Prevent overflow */
}

/* Body of PollFake to grow if needed but stay within bounds */
.PollFake-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Ensure logo container stays fixed size */
.fake-logo-container {
  display: inline-block;
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

/* Ensure header remains at the top */
.PollFake-header {
  text-align: center;
}

.PollFake-header h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Ensure color wheel and content inside stay at the top */
.PollFake-colorWheel {
  width: 100%;
  max-width: 175px;
  margin-bottom: 10px;
}

/* Ensure empty space at the bottom gets filled with the background */
.PollFake::after {
  content: '';
  display: block;
  flex-grow: 1;
  background-color: #333333; /* Grey background at the bottom */
}

/* Ensure the color bar doesn't overflow and fits */
.ColorBar-FakePoll .color-bar-container {
  height: 12px;
  margin-top: 0px;
}

.ColorBar-FakePoll .vote-count {
  font-size: 10px;
}

.nav-toggle-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.nav-toggle {
  position: relative;
  display: flex;
  background-color: #f0f0f0;
  border-radius: 30px;
  padding: 4px;
  cursor: pointer;
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.toggle-label {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  font-size: 14px;
  z-index: 2;
  transition: color 0.3s ease;
  user-select: none;
}

.toggle-slider {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  border-radius: 26px;
  transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1);
}

/* Creators state (default) */
.nav-toggle.creators .toggle-slider {
  background-color: #0252ff;
  transform: translateX(0);
}

.nav-toggle.creators .toggle-label:first-child {
  color: white;
}

.nav-toggle.creators .toggle-label:last-child {
  color: #555;
}

/* Brands state */
.nav-toggle.brands .toggle-slider {
  background-color: #ff6c00;
  transform: translateX(100%);
}

.nav-toggle.brands .toggle-label:first-child {
  color: #555;
}

.nav-toggle.brands .toggle-label:last-child {
  color: white;
}

/* Hover effects */
.nav-toggle:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

/* Optional: Add a subtle animation when switching */
.toggle-slider {
  transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1), 
              background-color 0.3s ease;
}

/* For better mobile experience */
@media (max-width: 768px) {
  .nav-toggle {
    width: 160px;
  }
  
  .toggle-label {
    font-size: 12px;
    padding: 8px 0;
  }
}




/* Mobile Styles */
@media (max-width: 768px) {



  .Category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  /* Hide the default nav on mobile */
  .opinari-footer-nav {
    display: none;
  }

  .wrapper{
    margin-top: 20px;
  }

  /* Show the burger menu on mobile */
  .nav-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  /* Hide nav-links by default */
  .nav-links {
    align-items: flex-end;
    background: #1a1a1a;
    display: none;
    flex-direction: column;
    padding: 20px 0;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1000;
    border-radius: 10px;
    border: solid 2px #434343;
    padding-left: 20px;
}



.nav-links li{
  margin-right: 20px;
}

  /* Show menu when open */
  .nav-links.open {
    display: flex;
  }

  /* Mobile Auth Buttons */
  .nav-auth {
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .nav-auth.open {
    display: flex
;
    flex-direction: column;
    margin-right: 20px;
    width: 70%;
}

  /* Hamburger Menu Button */
  .hamburger-menu {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1100;
  }


  .nav-signup {
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-family: Poppins;
    font-size: 14;
    padding: 8px 15px;
    width: 100%;
}

.nav-login {
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14;
  padding: 8px 15px;
  width: 100%;
}

}


.TermsPage-container{
  max-width: 800px;
    margin: auto;
    padding: 25px;
    background-color: #1a1a1a;
    border-radius: 15px;
    margin: 20px auto;

}


.TermsPage-container a{
  color: rgb(84, 138, 255);
}


.poll-share-button{
  margin-right: 12px;
    position: absolute;
    right: 0;
    color: grey;
}


.poll-more-button{
  color: grey;
    left: 0;
    margin-left: 12px;
    position: absolute;
}


@keyframes poll-pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.poll-pop-animation {
  animation: poll-pop 0.07s ease-in-out;
}






.confidence-divider{
  background: #2b2b2b;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 30px;
}


.confidence-data{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}







.loading-products {
  background: #ffffff;
    padding: 5px;
    border-radius: 5px;
    border: solid 2px orange;
  color: #121212;
  display: flex;
  font-size: 14px;
  gap: 8px;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
}

.amazon-loading-icon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}



.dot-animation::after {
  content: "";
  display: inline-block;
  width: 1ch;
  text-align: left;
  animation: dots 1.15s steps(3, end) infinite;
}

@keyframes dots {
  0% { content: ""; }
  33% { content: "."; }
  66% { content: ".."; }
  100% { content: "..."; }
}




.storefront-polls-container .color-picker-container {
  height: 100%;
}





.poll-footer-links{
  display: flex
;
    justify-content: center;
    gap: 15px;
    font-size: 12px;

}

.poll-footer-links a{
color: grey;

}