.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); /* Dark background for a cleaner look */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  overflow: hidden; /* Prevent the content from overflowing */
}

.cropper-container {
  background-color: #232323; /* Dark background for the container */
  padding: 20px;
  border-radius: 10px;
  max-width: 90vw; /* Set a reasonable max-width */
  max-height: 80vh; /* Limit the height of the container */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden; /* Prevent internal overflow */
  margin: 20px;
  border-radius: 10px;
}

.cropper-area {
  width: 100%;
  height: 60vh; /* Make sure the cropper takes up 60% of the viewport height */
  max-height: 400px;
  min-width: 30vw; /* Limit the height of the cropper itself */
  width: 80vw;
  position: relative;
  overflow: hidden; /* Make the cropper scrollable if too large */
  border-radius: 10px;

}

.controls {
  width: 100%; /* Make sure the controls take up the full width */
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

input[type="range"] {
  width: 80%; /* Adjust the width of the zoom slider */
}

.cropper-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Full width of the container */
  margin-top: 20px;
}

.cropper-buttons button {
  background-color: #00d131;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 45%; /* Ensure buttons don't take too much space */
}

.cropper-buttons button:nth-child(2) {
  background-color: #ed2517;
}

@media screen and (min-width: 768px) {
  .cropper-area {
    width: 100%;
    height: 60vh; /* Make sure the cropper takes up 60% of the viewport height */
    max-height: 400px;
    min-width: 30vw; /* Limit the height of the cropper itself */
    width: 40vw;
    position: relative;
    overflow: hidden; /* Make the cropper scrollable if too large */
    border-radius: 10px;
  }
}
