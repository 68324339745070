.badform-container {
  background-color: #111111;
  color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 380px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  height: 600px;
  opacity: 0.8;
}

.badform-container h3 {
  font-size: 18px;
  text-align: left;
  color: #ddd;
  margin: 0px;
  margin-bottom: 10px;
}

.badform-group {
  margin-bottom: 15px;
}

.badform-required {
  color: #ff5555;
}

/* Input Fields */
.badform-input {
  width: 90%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #252525;
  color: white;
  font-size: 14px;
}

.badform-input::placeholder {
  color: #888;
}

/* Radio button styles */
.badform-radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.badform-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.badform-radio input {
  display: none;
}

.badform-radio-custom {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #666;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  transition: 0.2s ease;
}

.badform-radio input:checked + .badform-radio-custom {
  background-color: #6e6e6e;
  border-color: #535353;
}

/* Checkbox styles */
.badform-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.badform-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.badform-checkbox input {
  display: none;
}

.badform-checkbox-custom {
  width: 16px;
  height: 16px;
  border: 2px solid #666;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  transition: 0.2s ease;
}

.badform-checkbox input:checked + .badform-checkbox-custom {
  background-color: #646464;
  border-color: #3c3c3c;
}

/* Submit button */
.badform-submit-btn {
  background-color: #3c3c3c;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  transition: 0.2s ease-in-out;
}

.badform-submit-btn:hover {
  background-color: #303030;
}

.badform-submit-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}


@keyframes pop-in {
  0% { transform: scale(0.9); opacity: 0; }
  80% { transform: scale(1.05); opacity: 1; }
  100% { transform: scale(1); }
}

@keyframes button-press {
  0% { transform: scale(1); }
  50% { transform: scale(0.9); }
  100% { transform: scale(1); }
}

.badform-pop {
  animation: pop-in 0.3s ease-out;
}

.badform-submit-btn.submit {
  animation: button-press 0.2s ease-out;
}

.badform-error {
  color: #ff4d4d;
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
}
