

/* Card styles */
.Onboarding-card {
  font-family: 'Poppins';
  background: url('../assets/bg2.png') no-repeat 50%;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 4px 20px #0000004d;
  color: #fff;
  max-width: 600px;
  padding: 2rem;
  text-align: left;
  padding-right: 2rem;
}

/* Welcome text */
.Onboarding-welcome {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Subtitle */
.Onboarding-subtitle {
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

/* Create button */
.Onboarding-create-button {
  background-color: #004eff; /* Blue */
  border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 2rem;
    padding: 10px 20px;
    transition: background-color .3s ease;
}

.Onboarding-create-button:hover {
  background-color: #004eff; /* Darker blue on hover */
}

/* Suggestion text */
.Onboarding-suggestion {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

/* Suggestions list */
.Onboarding-suggestions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Suggestion item button */
.Onboarding-suggestion-item {
  background-color: #111;
  color: white;
  border: 1px solid #333;
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.Onboarding-suggestion-item:hover {
  background-color: #004eff; /* Blue background on hover */
  border-color: #004eff;
}

/* Arrow icon */
.Onboarding-arrow {
  font-size: 1.2rem;
  margin-left: 1rem;
}


/* Mobile Responsive Styles */
@media (max-width: 768px) {
  /* Card styles */
  .Onboarding-card {
    max-width: 100%; /* Full width on mobile */
    padding: 1.5rem; /* Reduce padding */
    padding-right: 1.5rem; /* Remove excessive right padding */
    border-radius: 15px; /* Slightly smaller border radius for compact look */
    text-align: left; /* Center-align text for a better mobile experience */
  }

  /* Welcome text */
  .Onboarding-welcome {
    font-size: 1.5rem; /* Slightly smaller for mobile */
    margin-bottom: 1rem;
  }

  /* Subtitle */
  .Onboarding-subtitle {
    font-size: 0.9rem; /* Smaller subtitle font size */
    margin-bottom: 1rem;
  }

  /* Create button */
  .Onboarding-create-button {
    font-size: 0.9rem; /* Slightly smaller button font */
    padding: 8px 16px; /* Adjust padding */
    margin-bottom: 1.5rem;
    width: 100%; /* Make button full width */
  }

  /* Suggestion text */
  .Onboarding-suggestion {
    font-size: 0.8rem; /* Reduce font size for mobile */
    margin-bottom: 1rem;
  }

  /* Suggestions list */
  .Onboarding-suggestions-list {
    gap: 0.8rem; /* Reduce gap between suggestions */
  }

  /* Suggestion item button */
  .Onboarding-suggestion-item {
    font-size: 0.8rem; /* Smaller font size for mobile */
    padding: 8px 16px; /* Adjust padding for smaller screens */
    flex-direction: row; /* Keep row layout */
    gap: 0.5rem; /* Reduce spacing between content */
    text-align: left !important;
  }

  /* Arrow icon */
  .Onboarding-arrow {
    font-size: 1rem; /* Slightly smaller icon size */
    margin-left: 0.5rem; /* Adjust spacing */
  }
}
