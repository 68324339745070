.brand-ad-container {
  position: relative;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  margin-top: 10px;
  z-index: 1000;
  margin-bottom: 20px;
}

.brand-ad-container:hover {
  opacity: 0.8;
}

.brand-ad-image {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensures no part of the image overflows */
}

.brand-ad-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image scales correctly inside the box */
  border-radius: 8px; /* Keeps the rounded edges consistent */
}

.brand-trophy-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.brand-edit-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ff9900; /* Amazon-like orange */
  color: white;
  border: none;
  padding: 6px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  z-index: 10;
}

.brand-edit-btn:hover {
  background-color: #e68900;
}

.brand-edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.brand-edit-modal-content {
  background: #191919;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.brand-edit-modal-content h3 {
  margin-top: 0;
}

.brand-edit-modal input {
  color: white;
  background-color: #121212;
  width: 95%;
  padding: 8px;
  border: 1px solid #686868;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}

.brand-edit-modal-actions {
  display: flex;
  justify-content: space-between;
}

.brand-edit-modal button {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  flex: 1;
  margin: 5px;
}

.brand-edit-modal button:hover {
  background-color: #005bb5;
}

.brand-cancel-btn {
  background-color: #424242 !important;
  color: white !important;
}

.brand-cancel-btn:hover {
  background-color: #aaa !important;
}

.brand-error-text {
  color: rgb(210, 77, 77);
  font-size: 14px;
  margin-top: 5px;
}
