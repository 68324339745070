/* ProfileSettings.css */
.profile-settings {
  padding: 1.5rem;
  border-radius: 8px;
  font-family: 'Poppins';
  text-align: left;
  background-color: #191919;
  color: #ffffff;
  width: 100%;
  max-width: 450px;
}

.profile-settings__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.profile-settings__back-button {
  background: none;
  border: none;
  color: #ffffff;
  margin-right: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
}

.profile-settings__header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.profile-settings__form {
  display: flex;
  flex-direction: column;
}

.profile-settings__section {
  margin-bottom: 1rem;
}

.profile-settings__section label {
  display: block;
  margin-bottom: 0.5rem;
}

.profile-settings__section input[type="email"],
.profile-settings__section textarea {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
}

.profile-settings__save-button {
  padding: 0.75rem 1.5rem;
  background-color: #004eff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  max-width: 200px;
  width: 100%;
}

.profile-settings__save-button:hover {
  background-color: #004eff;
}

.profile-settings__delete-account {
  padding: 0.75rem 1.5rem;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  max-width: 200px;
  width: 100%;
}

.profile-settings__delete-account:hover {
  background-color: #b21f2d;
}


.profile-settings_heading{

margin-bottom: 5px;
}

.profile-settings__bio-input {
  width: 95%;
  padding: 0.75rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  margin-bottom: 0.5rem;
}






@media (max-width: 768px) {
  .profile-settings {
    background-color: #191919;
    border-radius: 8px;
    color: #fff;
    font-family: Poppins;
    max-width: 450px;
    padding: 1.5rem;
    text-align: left;
    width: auto;
  }
  
  }