.PrivacyPage-container{
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.75;
    background-color: #1a1a1a;
    padding: 20px;
    margin: 20px;
    margin: auto;
    margin-top: 20px;
    border-radius: 20px;
    text-align: left;
}


.PrivacyPage-container a{
    color: rgb(2, 162, 255);
}