.advertisements-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}

.ad-boxes{
  display: contents;
}

.query-info {
  font-size: 16px;
  color: #a5a5a5;
}

.ad-box {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  width: 98%; /* Optional: set a fixed width */
  height: auto; /* Optional: maintain aspect ratio */
}

.ad-box img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.1s ease, filter 0.1s ease; /* Add smooth transitions */
}

.ad-box.clicked img {
  filter: brightness(0.5); /* Darkens the image */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 18px;
  font-weight: bold;
  pointer-events: none;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease;
}

.ad-box.clicked .overlay {
  opacity: 1; /* Show the overlay on click */
}


































.advertisements-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  margin: auto;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.advertisements-container:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.advertisements-container a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.advertisements-container img {
  width: 100%;
  max-width: 250px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.advertisements-container p {
  margin: 5px 0;
  font-size: 16px;
}

.advertisements-container p:first-of-type {
  font-weight: bold;
  font-size: 18px;
}

.advertisements-container p:last-of-type {
  color: #007600;
  font-size: 16px;
}

@media (max-width: 400px) {
  .advertisements-container {
    width: 90%;
  }

  .advertisements-container img {
    max-width: 200px;
  }
}

