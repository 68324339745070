.large-ad-container {
  position: relative;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  color: #1a1a1a;
  display: block;
  overflow: hidden;
  padding: 15px;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  margin-top: 10px;
  z-index: 1000;
  margin-bottom: 20px;
}

.large-trophy-icon {
  margin-top: -40px;
}

.large-ad-container:hover {
  opacity: 0.8;
}

.large-ad-image {
  width: 125px;
  height: 125px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #333;
  font-weight: bold;
  overflow: hidden;
  justify-self: center;
}

.large-ad-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.large-ad-details {
  flex: 1;
  text-align: left;
  min-width: 0;
}

.large-ad-name {
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.large-ad-price {
  font-size: 14px;
  margin-top: 5px;
}

.large-ad-votes {
  font-size: 12px;
}



.price-votes-button-wrapper{
  display: flex
  ;
      justify-content: space-between;
      margin-top: 10px;
}


@media (max-width: 768px) {

  .large-ad-container {
    position: relative;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    color: #1a1a1a;
    display: block;
    overflow: hidden;
    padding: 15px;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    margin-top: 10px;
    z-index: 1000;
    margin-bottom: 0px;
  }

}