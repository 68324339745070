.ad-container {
    position: relative;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    color: #1a1a1a;
    display: flex;
    height: 60px;
    overflow: hidden;
    padding: 10px;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    margin-top: 10px;
    z-index: 1000;
    margin-bottom: 20px;
}




.ad-group-heading {
  color: #fff;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  /* margin-left: 10px; */
  margin-right: 10px;
  padding: 5px;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;
  border-radius: 5px;
  margin-left: 0px;
  font-size: 18px;
}



.ad-container-editor {
  position: relative;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  color: #1a1a1a;
  display: flex;
  overflow: hidden;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  margin-top: 10px;
  z-index: 1000;
  margin-bottom: 10px;
  width: 400px;
  height: 100px;
}


.ad-container-editor-single {
  position: relative;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  color: #1a1a1a;
  display: flex;
  overflow: hidden;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  margin-top: 20px;
  z-index: 1000;
  margin-bottom: 10px;
  width: 400px;
}


.ad-editor-price{
  margin-bottom: 20px;
}

.trophy-icon {
    margin-top: -40px;
  }
  

.ad-container:hover {
    opacity: 0.8;
}

.ad-image {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #333;
    font-weight: bold;
    overflow: hidden; /* Ensures no part of the image overflows */
}

.ad-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image scales correctly inside the box */
    border-radius: 8px; /* Keeps the rounded edges consistent */
}

.ad-details {
    flex: 1;
    padding-left: 10px;
    text-align: left;
    margin-left: 10px;
}

.ad-name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

.ad-price {
    font-size: 14px;
    margin-top: 5px;
    border: none;
}

.ad-link{
  border: none;
}

.ad-votes{
    font-size: 12px;
}


.ad-details {
    flex: 1;
    padding-left: 10px;
    text-align: left;
    margin-left: 10px;
    min-width: 0; /* Allow child elements to shrink */
  }
  
  .ad-name {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  





 /* Edit Button - Positioned in Bottom Right */
.edit-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ff9900; /* Amazon-like orange */
    color: white;
    border: none;
    padding: 6px 10px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    z-index: 10;
  }
  
  .edit-btn:hover {
    background-color: #e68900;
  }
  
  /* Edit Modal Popup */
  .edit-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .edit-modal-content {
    background: #191919;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .edit-modal-content h3 {
    margin-top: 0;
  }
  
  .edit-modal input {
    color: white;
    background-color: #121212;
    width: 95%;
    padding: 8px;
    border: 1px solid #686868;
    border-radius: 4px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .edit-modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .edit-modal button {
    background-color: #0073e6;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    flex: 1;
    margin: 5px;
  }
  
  .edit-modal button:hover {
    background-color: #005bb5;
  }
  
  /* Cancel Button */
  .cancel-btn {
    background-color: #424242 !important;
    color: white !important;
  }
  
  .cancel-btn:hover {
    background-color: #aaa !important;
  }




  /* Error Message */
  .error-text {
    color: rgb(210, 77, 77);
    font-size: 14px;
    margin-top: 5px;
  }


  .ad-editor-image{
    height: inherit;

  }


  .ad-editor-image-single{
    height: inherit;
    display: flex;

  }

  .ad-editor-image-single img{
    width: 100%;

  }


  .ad-link{
    background-color: #0f0f0f;
    padding: 8px 10px;
    border-radius: 10px;
    font-family: 'Poppins';
    width: 96%;
    color: white;
  }