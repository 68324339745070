/* ===== Dark Theme EasyBuilder Input ===== */


.EasyBuilder-input::placeholder {
  color: #888;                      /* Lighter placeholder text */
}

.EasyBuilder-input:focus {
  border-color: #666;               /* Brighten border on focus */
}

/* ===== Dark Theme EasyBuilder Preset Suggestions ===== */
.EasyBuilder-suggestions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 20px;
}

.EasyBuilder-suggestion-item {
  background-color: #111;           /* Very dark background */
  color: #fff;                      /* White text */
  border: 1px solid #333;           /* Dark border */
  padding: 10px 20px;
  font-size: 0.9rem;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.EasyBuilder-suggestion-item:hover {
  background-color: #004eff;        /* Blue highlight on hover */
  border-color: #004eff;
}

/* Arrow icon for preset suggestions (if used) */
.EasyBuilder-arrow {
  font-size: 1.2rem;
  margin-left: 1rem;
}







.EasyBuilder-input {
  background-color: #222;
  border: 1px solid #444;
  border-radius: 40px;
  box-sizing: border-box;
  color: #eee;
  font-size: 16px;
  outline: none;
  padding: 15px;
  transition: border-color .3sease;
  width: 63%;
}






.EasyBuilder-create-button {
  align-items: center;
    background-color: #0252ff;
    border: none;
    border-radius: 40px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    gap: 10px;
    padding: 15px 20px;
    transition: background-color .3sease;
    margin-left: 10px;
}

.EasyBuilder-create-button:hover {
  background-color: #004ae6;
}

.EasyBuilder-create-button:disabled {
  opacity: 1;
  cursor: not-allowed;
}





.easy-builder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
}



.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ===== Mobile Responsive Styles ===== */
@media (max-width: 768px) {
  .EasyBuilder-input {
    background-color: #222;
    border: 1px solid #444;
    border-radius: 40px;
    box-sizing: border-box;
    color: #eee;
    font-size: 16px;
    outline: none;
    padding: 15px;
    transition: border-color .3sease;
    width: 90%;
    margin-bottom: 20px;
  }
  
  .EasyBuilder-suggestions-list {
    gap: 0.8rem;
  }
  
  .EasyBuilder-suggestion-item {
    font-size: 0.8rem;
    padding: 8px 16px;
    flex-direction: row;
    gap: 0.5rem;
    text-align: left !important;
  }
  
  .EasyBuilder-arrow {
    font-size: 1rem;
    margin-left: 0.5rem;
  }


  .EasyBuilder-create-button {
    align-items: center;
    background-color: #0252ff;
    border: none;
    border-radius: 40px;
    color: #fff;
    cursor: pointer;
    display: flex
;
    font-size: 1rem;
    gap: 10px;
    margin-left: 0px;
    padding: 15px 20px;
    transition: background-color .3sease;
}
}
